// Setting the type
// Also look at settings/bootstrap

@mixin f-helvetica-light() {
  font-weight: 300;
}

@mixin f-helvetica-normal() {
  font-weight: 400;
}

@mixin f-helvetica-bold() {
  font-weight: 700;
}

%body-copy {
  line-height: 1.55;
  font-size: 1rem;

  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
  }
}

%display-xxl {
  @include f-helvetica-bold();
  line-height: 1.1;
  font-size: 2.5rem;
  color: var(--c-headline);

  @include media-breakpoint-up(md) {
    font-size: 3.125rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 4.375rem;
  }
}

%display-xl {
  @include f-helvetica-bold();
  line-height: 1.2;
  font-size: 1.625rem;
  color: var(--c-headline);

  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 2.5625rem;
  }
}

%display-l {
  @include f-helvetica-bold();
  line-height: 1.2;
  font-size: 1.4357rem;
  color: var(--c-headline);

  @include media-breakpoint-up(lg) {
    font-size: 2rem;
  }
}

%display-m {
  @include f-helvetica-bold();
  line-height: 1.2;
  font-size: 1.125rem;
  color: var(--c-headline);

  @include media-breakpoint-up(lg) {
    font-size: 1.4357rem;
  }
}

%display-s {
  @include f-helvetica-light();
  line-height: 1.2;
  font-size: 1.125rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.4357rem;
  }
}

@mixin text-m() {
  @include f-helvetica-normal();
  line-height: 1.5;
  font-size: 1rem;
}
%text-m {
  @include text-m;
}

@mixin text-s() {
  @include f-helvetica-normal();
  line-height: 1.5;
  font-size: .875rem;
}
%text-s {
  @include text-s;
}

%btn {
  @include f-helvetica-bold();
  line-height: 1.5;
  font-size: 1rem;
}
