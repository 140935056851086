@use "sass:math";

.text {

  $root: &;
  overflow: hidden;

  .richtext:not(:first-child) {
    margin-top: space(H);
    @include media-breakpoint-up(md) {
      margin-top: space(G);
    }
  }

  &__entries {
    justify-content: center;

    &--align-left {
      justify-content: flex-start;
    }

    margin-bottom: -1 * space(G);
    @include media-breakpoint-up(md) {
      margin-bottom: -1 * space(E);
    }

    &--large-spacing {
      margin-bottom: -1 * space(D);
      @include media-breakpoint-up(md) {
        margin-bottom: -1 * space(B);
      }
    }
  }

  &__entry {
    @include make-col(12);

    margin-bottom: space(G);
    @include media-breakpoint-up(md) {
      margin-bottom: space(E);
    }

    #{$root}__entries--col-1 & {
      @include media-breakpoint-up(lg) {
        @include make-col(6);
        @include make-col-offset(3);
        margin-right: percentage(math.div(3, 12));
      }
    }

    #{$root}__entries--large-spacing & {
      margin-bottom: space(D);
      @include media-breakpoint-up(md) {
        margin-bottom: space(B);
      }
    }

    #{$root}__entries--align-left#{$root}__entries--col-1 & {
      @include media-breakpoint-up(lg) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    #{$root}__entries--col-2 & {
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
    }

    #{$root}__entries--col-3 & {
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
    }

    #{$root}__entries--col-4 & {
      @include media-breakpoint-up(lg) {
        @include make-col(3);
      }
    }
  }
}
