.image {
  margin: 0;
  font-size: 0;

  span {
    display: inline-block;
    max-width: 100%;
  }

  &__wrapper {
    height: 0;
    position: relative;
    overflow: hidden;
    font-size: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }

  figcaption {
    @include text-m;
  }
}

// global img
img {
  max-width: 100%;
  height: auto;
}