.breadcrumb {
  margin-bottom: space(H);

  ul {
    @include text-s();
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: flex;

    &:not(:last-child):after {
      content: '»';
      color: transparentize($c-black, .35);
      font-size: rem(11);
      padding: rem(2) rem(4) 0;
    }

    a {
      color: transparentize($c-black, .35);

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.breadcrumb-container.ep-module {
  margin-top: space(H);

  .breadcrumb {
    margin-bottom: 0;
  }
}
