.section {

  $root: &;

  &.bg-grey {
    background: var(--c-bg-light);

    padding-top: space(D);
    padding-bottom: space(D);

    @include media-breakpoint-up(md) {
      padding-top: space(B);
      padding-bottom: space(B);
    }

    @include media-breakpoint-up(lg) {
      padding-top: space(A);
      padding-bottom: space(A);
    }

    + #{$root}.bg-grey {
      padding-top: 0;
      margin-top: 0;
    }
  }

  > .ep-module {
    margin-top: 0;

    & + .ep-module {
      &::before {
        content: '';
        display: block;
        width: calc(100% - #{2*$container-padding});
        max-width: $container-max-width - 2 * $container-padding-xl;
        margin-left: auto;
        margin-right: auto;
        height: 1px;
        background: var(--c-divider-dark);
        margin-top: space(F);
        margin-bottom: space(F);

        @include media-breakpoint-up(md) {
          margin-top: space(E);
          margin-bottom: space(E);
          width: calc(100% - #{2*$container-padding-md});
        }

        @include media-breakpoint-up(lg) {
          margin-top: space(D);
          margin-bottom: space(D);
        }
      }
    }
  }

  &--hide-divider {
    > .ep-module  + .ep-module::before {
      background: none;
      height: space(E);
      margin-bottom: 0;
      margin-top: 0;
      @include media-breakpoint-up(md) {
        height: space(B);
      }
      @include media-breakpoint-up(lg) {
        height: space(A);
      }
    }
  }
}
