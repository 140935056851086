.ah-link {
	@include f-helvetica-bold;
	display: inline-block;
	color: var(--primary);
	transition: $transition-base;

	svg {
		fill: var(--primary);
		position: relative;
		top: -.05em;
		margin-left: 7px;
		transform: translateX(0px);
		transition: $transition-base;
	}

	&:hover {
		text-decoration: none;
		svg {
			transform: translateX(5px);
			fill: $link-hover-color;
		}
	}
}

[data-href] {
  cursor: pointer;
}
