.js-collapse-wrapper {
  overflow: hidden;
  transition: .2s max-height ease-in-out;

  @media screen {
    html.js & {
      max-height: 0;
    }
  
    html.js .active + & {
      max-height: none; // initial state, if one would be selected later on
    }
  }
}