.footer {
  padding-top: space(C);
  padding-bottom: space(C);
  text-align: center;
  background: var(--c-footer);
  color: $c-white;

  .container-fluid {
    overflow: hidden;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
    }
  }

  a {
    color: $c-white;
    display: inline-flex;
    align-items: center;

    .icon {
      fill: currentColor;

      width: em(20, 16);
      height: em(20, 16);
      margin-left: em(6, 16);
    }

    html.has-hover &:hover {
      text-decoration: underline;
    }
  }

  &__nav {
    font-size: rem(16);
    line-height: em(20, 16);
    margin: 0 -.5 * space(G) -1 * space(H);

    a {
      @include f-helvetica-bold;
    }

    li {
      margin: 0 .5 * space(G) space(H);
    }
  }

  &__footnote-nav {
    margin: space(G) -.5 * space(H) -1 * space(I);

    li {
      margin: 0 .5 * space(H) space(I);
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__social {
    &-entry {
      margin: 0 em(8, 16) em(5, 16);

      .icon {
        display: block;
        width: em(22, 16);
        height: em(22, 16);
        margin-left: 0;
      }

      &--linkedin {
        position: relative;
        top: em(-1, 16);
      }
    }
  }
}
