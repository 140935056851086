// Setting the grid specifications
$container-padding: 20px;
$container-padding-md: 32px;
$container-padding-xl: 32px;

.container-fluid {
	padding-left: $container-padding;
	padding-right: $container-padding;

	@include media-breakpoint-up(md) {
		padding-left: $container-padding-md;
		padding-right: $container-padding-md;
	}

	@include media-breakpoint-up(lg) {
		padding-left: $container-padding-xl;
		padding-right: $container-padding-xl;

		max-width: 1240px;
		margin: 0 auto;
	}

	html.text-size-large & {
		max-width: none;
	}
}
