.video-stage {
  $root: &;

  position: relative;

  > button,
  .container-fluid > button {
    display: block;
    appearance: none;
    width: 100%;

    padding: 0;
    border: none;
    margin: 0;
    background: transparent;
  }

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: $transition-base;

    width: rem(64);
    height: rem(64);

    border-radius: 50%;
    border: 1px solid #fff;
    background: rgba(255,255,255,.35);

    .icon {
      width: rem(32);
      height: rem(32);
      fill: #fff;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: $transition-base;
    }
  }

  html.has-hover & button:hover #{$root}__play-button,
  & button.focus-visible #{$root}__play-button {
    .icon {
      fill: #000;
    }

    background: #fff;
    transform: translate(-50%, -50%) scale(1.15);
  }
}
