.tns {
  &-outer {
    position: relative;
  }

  &-controls {
    display: none;

    button {
      background: transparentize($c-white, .5) url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNi45OTkgMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgN2MwLS4yMjguMDc3LS40NTUuMjMyLS42NGw1LTZhMSAxIDAgMSAxIDEuNTM2IDEuMjhMMi4yOTIgNy4wMWw0LjMxNSA1LjM2M2ExIDEgMCAwIDEtMS41NTggMS4yNTRsLTQuODI4LTZBMSAxIDAgMCAxIDAgNyIgc3R5bGU9ImZpbGw6I2ZmZiIvPjwvc3ZnPg==') no-repeat rem(15) rem(12);
      background-size: rem(7) rem(14);
      border: rem(1) solid $c-white;
      border-radius: 50%;
      color: transparent;
      height: rem(40);
      font: 0/0 a;
      padding: 0;
      position: absolute;
      top: calc(50% - #{rem(20)});
      width: rem(40);
      z-index: 1;

      &:first-child {
        left: space(I);
      }

      &:last-child {
        right: space(I);
        transform: rotate(180deg);
      }

      @include media-breakpoint-up(md) {
        &:first-child {
          left: space(E);
        }

        &:last-child {
          right: space(E);
        }
      }

      @include media-breakpoint-up(xl) {
        &:first-child {
          left: space(A);
        }

        &:last-child {
          right: space(A);
        }
      }
    }

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  &-nav {
    left: 0;
    bottom: space(H);
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    z-index: 1;

    button {
      background: none;
      border: none;
      height: rem(24);
      margin: 0 space(I);
      padding: 0;
      width: rem(24);

      &:after {
        border: rem(1) solid $c-white;
        border-radius: 50%;
        content: '';
        display: block;
        height: 100%;
        transition: $transition-base;
        width: 100%;
      }

      &.tns-nav-active:after {
        background: var(--c-accent);
        border-width: rem(8);
      }
    }

    @include media-breakpoint-up(lg) {
      bottom: space(F);
    }
  }
}
