.checkbox {

  $root: &;

  &.has-error {
    #{$root}__label {
      color: $c-error;
    }

    .invalid-feedback {
      display: block;
      margin-top: 0;
    }

    label input {
      & + div {
        &::before {
          border: 1px solid $c-error;
        }
      }
    }
  }

  &__label {
    @include f-helvetica-normal();
    font-size: rem(13);
    line-height: em(16, 13);
    margin-bottom: space(I);
  }

  label {
    display: flex;
    align-items: center;
    position: relative;
    min-height: rem(40);
    cursor: pointer;
    font-size: rem(16);
    line-height: em(22, 16);
    margin: 0;

    input {
      opacity: 0;
      width: rem(24);
      height: rem(24);
      position: absolute;
      top: rem(8);
      left: 0;
      pointer-events: none;
    }

    div {
      padding: em(8, 16) em(24 + 16, 16);

      &::before {
        content: '';
        width: em(24, 16);
        height: em(24, 16);

        border: 1px solid rgba(0,0,0,.4);
        border-radius: 2px;

        background: #F8F8F8;

        position: absolute;
        top: em(8, 16);
        left: 0;
      }
    }

    input.focus-visible,
    html.has-hover &:hover input {
      & + div {
        &::before {
          border-color: var(--c-accent);
          background-color: $c-white;
        }
      }
    }

    :checked + div,
    input.focus-visible:checked + div,
    html.has-hover &:hover input:checked + div {
      &::before {
        background: $c-black;
      }

      &::after {
        content: '';
        width: em(24, 16);
        height: em(24, 16);
        background: inline-svg('checkmark', $c-white);
        background-size: cover;
        background-position: center;

        position: absolute;
        top: em(8, 16);
        left: 0;
        pointer-events: none;
      }
    }

    input:checked.focus-visible {
      & + div {
        &::before {
          outline: 2px dashed $c-black;
        }
      }
    }

    .richtext {
      max-width: 100%;

      html.text-size-large & {
        hyphens: auto;
      }
    }
  }
}
