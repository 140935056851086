body {
  @extend %text-m;
  @include f-helvetica-normal();

  color: $c-black;  // fallback all black
  color: var(--c-text);

  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
  }
}

.t-centered {
  text-align: center;
}

.t-right {
  text-align: right;
}

p {
  margin: 0;
}

// for the component library
.f-helvetica-light { @include f-helvetica-light(); }
.f-helvetica-normal { @include f-helvetica-normal(); }
.f-helvetica-bold { @include f-helvetica-bold(); }

// * > .text-class for overriding richtext h1 h2 h3 p default
.display-xxl,
.richtext .display-xxl {
  @extend %display-xxl;
}

.display-xl,
.richtext .display-xl {
  @extend %display-xl;
}

.display-l,
.richtext .display-l {
  @extend %display-l;
}

.display-m,
.richtext .display-m {
  @extend %display-m;
}

.display-s,
.richtext .display-s {
  @extend %display-s;
}

.text-m,
.richtext .text-m {
  @extend %text-m;
}

.text-s,
.richtext .text-s {
  @extend %text-s;
}

.text-btn,
.richtext .text-btn {
  @extend %btn;
}
