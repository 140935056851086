.sticky-call-to-action {
  display: flex;
  align-items: center;
  position: fixed;
  right: rem(30);
  bottom: 0;
  color: $c-white;
  background: var(--c-cta);
  box-shadow: 0 rem(2) rem(6) 0 rgba(0, 0, 0, 0.15), 0 rem(8) rem(24) 0 rgba(0, 0, 0, 0.15);
  border-radius: rem(12) rem(12) 0 0;
  font-size: rem(16);
  font-weight: bold;
  padding: rem(12) rem(24);
  z-index: 20;

  .icon {
    width: rem(18);
    height: rem(18);
    fill: currentColor;
    margin-right: rem(8);
    transition: $transition-base;
  }

  &:hover,
  &:focus {
    color: $c-white;

    .icon {
      transform: scale(#{calc(24 / 18)});
    }
  }
}
