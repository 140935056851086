.richtext {

  > *,
  & {
    @include text-m;
  }

  * {
    margin: 0;
  }

  p, ul, ol {
    @include text-link;
  }

  b, strong {
    font-weight: 700;
  }

  i, em {
    font-style: italic;
  }

  // hoch / tiefgestellt
  sub,
  sup {
    font-size: rem(12);
    line-height: em(15, 12);
    margin-left: em(.75, 12);
  }

  sub {
    bottom: -.25em;
  }
  sup {
    top: -.5em;
  }

  ol {
    counter-reset: ol-counter;
    list-style: none;
    padding-left: rem(40);

    li {
      counter-increment: ol-counter;
      position: relative;

      &::before {
        content: counter(ol-counter) '.';
        position: absolute;
        top: 0;
        right: 100%;
        margin-right: rem(5);
      }
    }
  }

  ul {
    padding-left: rem(40);
  }

  ol, ul {
    li {
      margin: 0;
    }

    li + li {
      margin-top: rem(8);
    }
  }

  blockquote {
    @include f-helvetica-light();
    font-size: rem(18);
    line-height: 1.2;
  }

  .txt-btn,
  .download-link,
  .external-link {
    margin-bottom: rem(16);
  }

  .table-responsive {
    overflow: auto;
  }

  table {
    font-size: rem(16);
    line-height: em(22, 16);

    overflow: hidden;

    td, th {
      padding-bottom: rem(24);

      padding-left: rem(12);
      padding-right: rem(12);

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &.full-width {
      width: 100%;
    }
  }

  figure {
    margin: rem(24) 0;
  }

  > :first-child {
    &,
    > :first-child,
    > :first-child > :first-child {
      margin-top: 0;
    }
  }

  > :last-child {
    &,
    > :last-child,
    > :last-child > :last-child {
      margin-bottom: 0;
    }
  }
}
