.accordion {

  $root: &;

  &__entry {
    @media print {
      page-break-inside: avoid;
    }
  }

  &__entry-headline {
    color: var(--c-text);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: space(H);
    z-index: 1;
    position: relative;

    // button unset
    width: 100%;
    border: 0;
    border-radius: 0;
    background: transparent;
    text-align: left;

    .icon {
      width: rem(24);
      height: rem(24);
      fill: currentColor;
      transition: $transition-base;
    }

    html.has-hover &:hover {
      background: var(--c-bg-light);
    }

    + div {
      background: linear-gradient(to right, var(--c-bg-light), var(--c-bg-light)) no-repeat;
      background-position: 50% 0;
      background-size: 100% calc(100% - #{space(H)});
    }

    &.active {
      background: linear-gradient(to right, var(--c-bg-light), var(--c-bg-light));

      .icon {
        transform: rotate(180deg);
      }

      &,
      + div {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: rem(2);
          height: 100%;
          background: var(--c-accent);
        }
      }

      + div::before {
        height: calc(100% - #{space(H)});
      }
    }
  }

  &__icon-wrapper {
    flex: 0 0 auto;
    margin-left: space(H);

    @media print {
      display: none;
    }
    html.no-js & {
      display: none;
    }
  }

  &__entry-content {
    padding: space(G) space(H) (space(G) + space(H));
    @include media-breakpoint-up(md) {
      padding: space(F) space(F) (space(F) + space(H));
    }

    position: relative;

    /*
    * Top Border
    */
    &::before {
      content: '';
      position: absolute;
      top: 0;
      height: 1px;
      background: $c-grey;

      left: space(H);
      right: space(H);
    }

    @media print {
      padding: space(F);
    }
  }

  .richtext {
    max-width: rem(760);
  }
}
