.image-teaser {

  $root: &;

  &__intro {
    margin-bottom: space(F);

    @include media-breakpoint-up(md) {
      margin-bottom: space(E);
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: space(D);
    }
  }

  &__headline {
    @include media-breakpoint-up(lg) {
      text-align: center;
    }
  }

  &__entries {
    justify-content: center;

    margin-bottom: -1 * space(F);

    @include media-breakpoint-up(md) {
      margin-bottom: -1 * space(E);
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: -1 * space(D);
    }

    > * {
      margin-bottom: space(F);

      @include media-breakpoint-up(md) {
        margin-bottom: space(E);
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: space(D);
      }
    }
  }

  &__entry-text {
    margin-top: space(H);
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-top: space(G);
    }
  }

  /*
  *   Text Styling
  */
  &__entry {

    > a {
      display: block;
      color: var(--c-text);
      text-decoration: none;
      height: 100%;

      html.has-hover &:hover,
      &:focus {
        text-decoration: none;
      }

      > div,
      .image {
        transition: $transition-base;
      }
    }

    .richtext:not(:first-child) {
      margin-top: space(H);
    }

    .row {
      @include media-breakpoint-up(md) {
        @include media-breakpoint-down(md) {
          align-items: center;
        }
      }
    }
  }

  &__entry-headline {
    @extend %display-m;
    display: inline-flex;
    font-weight: bold;
    font-size: rem(16);
    line-height: em(20, 16);
    transition: $transition-base;

    .icon {
      width: rem(18);
      height: rem(18);
      margin-right: em(8, 16);
      transition: $transition-base;
      transform: scale(1);
      fill: currentColor;

      align-self: center;
      flex: 0 0 auto;

      &:last-child {
        margin-left: em(8, 16);
        margin-right: 0;
      }
    }

    span {
      padding: 5px 0 3px;
      display: inline-block;

      background-image: linear-gradient(to right, var(--c-link), var(--c-link));
      background-repeat: no-repeat;
      background-size: 100% 1px;
      background-position: 0 100%;
      transition: $transition-base;
    }

    &--external {
      .icon {
        order: 1;
        margin-left: .5em;
        margin-right: 0;
      }
    }
  }

  &__entry-footer:not(:first-child) {
    margin-top: space(H);
  }

  &__entry-link {
    display: inline-flex;
    font-weight: bold;
    font-size: rem(16);
    line-height: em(20, 16);
    transition: $transition-base;

    .icon {
      width: rem(18);
      height: rem(18);
      margin-right: em(8, 16);
      transition: $transition-base;
      transform: scale(1);
      fill: currentColor;

      align-self: center;
      flex: 0 0 auto;

      &:last-child {
        margin-left: em(8, 16);
        margin-right: 0;
      }
    }

    span {
      padding: 5px 0 3px;
      display: inline-block;

      background-image: linear-gradient(to right, var(--c-link), var(--c-link));
      background-repeat: no-repeat;
      background-size: 100% 1px;
      background-position: 0 100%;
      transition: $transition-base;
    }

    &--external {
      .icon {
        order: 1;
        margin-left: .5em;
        margin-right: 0;
      }
    }
  }

  html.has-hover &__entry > a:hover,
  &__entry > a:focus {

    > div,
    .image {
      transform: translateY(-4px);
    }

    #{$root}__entry-headline {
      transform: translateY(-2px);
      span {
        background-size: 100% 3px;
      }
    }

    #{$root}__entry-link {
      transform: translateY(-2px);
      span {
        background-size: 100% 3px;
      }
    }
  }
}
