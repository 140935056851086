.quotes {
  &__slide {
    margin: 0 auto;
    padding: 0 0 rem(56);
    max-width: rem(760);

    .quotes__content {
      background: url(../images/quotationmark.svg) no-repeat center top;
      background-size: rem(64) rem(64);
      padding-top: rem(80);
      text-align: center;
    }

    .quotes__quote {
      @extend %display-s;

      &:before {
        content: '„';
      }

      &:after {
        content: '“';
      }
    }

    .quotes__author {
      @extend %text-m;
      margin-top: rem(16);
    }

    &--with-image {
      .quotes__image {
        position: relative;

        .image {
          width: rem(162);
        }
      }

      .quotes__eppm-line {
        display: none;

        @at-root .theme-eppm & {
          display: block;
          position: absolute;
          left: rem(-36);
          right: rem(-36);
          bottom: rem(-27);

          svg {
            width: 100%;
            height: auto;

            path {
              transition: stroke-dashoffset 1800ms cubic-bezier(0.215, 0.61, 0.355, 1);
              stroke-dashoffset: 736px;

              @media (prefers-reduced-motion: reduce) {
                stroke-dashoffset: 0;
              }
            }
          }

          &.js-visible,
          .no-js & {
            svg path {
              stroke-dashoffset: 0;
            }
          }
        }
      }

      .quotes__eppg-lines {
        display: none;

        @at-root .theme-eppg & {
          display: block;
          position: absolute;
          left: rem(-34);
          top: rem(-16);
          width: calc(100% + #{rem(68)});
          height: calc(100% + #{rem(32)});

          svg {
            color: var(--c-accent);
            max-width: 100%;
            height: auto;
          }

          @include media-breakpoint-up(md) {
            left: rem(-37);
            top: rem(33);
            width: 100%;
            height: 100%;
          }

          @include media-breakpoint-up(lg) {
            top: rem(51);
            left: rem(-61);
          }
        }
      }

      @include media-breakpoint-down(sm) {
        .quotes__image {
          margin: 0 auto;
          margin-top: rem(24);
          width: rem(162);
        }
      }

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        max-width: rem(1136);

        .quotes__content {
          flex: 1 1 auto;
          background-position-x: left;
          text-align: left;
        }

        .quotes__image {
          flex: 0 0 auto;
          margin-left: rem(84);

          .image {
            width: rem(216);
          }
        }

        @at-root .theme-eppm & .quotes__eppm-line {
          left: rem(-48);
          right: rem(-48);
          bottom: rem(-36);
        }
      }

      @include media-breakpoint-up(lg) {
        .quotes__image {
          margin-left: rem(140);

          .image {
            width: rem(324);
          }
        }

        @at-root .theme-eppm & .quotes__eppm-line {
          left: rem(-72);
          right: rem(-72);
          bottom: rem(-54);
        }
      }
    }
  }

  &--single {
    .quotes__slide {
      padding-bottom: 0;
    }
  }

  .tns-nav {
    bottom: 0;

    button {
      border: rem(1) solid var(--c-divider-dark);
      border-radius: 50%;

      &.tns-nav-active:after {
        border-width: rem(7);
      }

      &:not(.tns-nav-active) {
        &:after {
          border-width: 0;
        }

        &:hover,
        &:focus {
          background: var(--c-bg-light);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__slide {
      padding-bottom: rem(72);
    }

    &__author {
      margin-top: rem(24);
    }
  }
}
