.eppg-stage {
  padding-top: space(D);

  &__text {
    max-width: rem(400);
    margin-bottom: space(G);

    .display-xxl {
      margin-bottom: 0;
    }

    .richtext,
    .txt-btn {
      margin-top: space(G);
    }
  }

  &__image {
    position: relative;
    text-align: right;

    .image {
      display: inline-block;
      width: 80%;
      margin-top: rem(70);
    }
  }

  &__bg-lines {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    aspect-ratio: 688/456;

    svg {
      color: var(--c-accent);
      max-width: 100%;
      height: auto;
    }
  }

  @include media-breakpoint-up(sm) {
    &__text {
      margin-bottom: 0;
    }

    &__image {
      .image {
        margin-top: rem(50);
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: space(B);
  }

  @include media-breakpoint-up(lg) {
    padding-top: space(A);

    &__image {
      .image {
        margin-top: rem(65);
        width: 60%;
      }
    }

    &__bg-lines {
      width: 72%
    }
  }
}
