.partner-logos {

  $root: &;

  background: var(--c-bg-dark);
  color: $c-white;

  padding-top: space(D);
  padding-bottom: space(D);

  @include media-breakpoint-up(md) {
    padding-top: space(B);
    padding-bottom: space(B);
  }

  @include media-breakpoint-up(lg) {
    padding-top: space(A);
    padding-bottom: space(A);
  }

  &__intro {
    * {
      color: $c-white;
      margin: 0;
    }

    * + * {
      margin-top: space(I);
    }
  }

  &__image {
    * + & {
      margin-top: space(F);

      @include media-breakpoint-up(md) {
        margin-top: space(E);
      }

      @include media-breakpoint-up(lg) {
        margin-top: space(D);
      }
    }
  }

  &__divider {
    margin: space(F) 0;
    height: 1px;
    background: var(--c-divider-light);

    @include media-breakpoint-up(md) {
      margin: space(E) 0;
    }

    @include media-breakpoint-up(lg) {
      margin: space(D) 0;
    }
  }

  &__logos {
    flex-wrap: wrap;
    justify-content: center;

    gap: space(F) space(E);

    @include media-breakpoint-up(md) {
      gap: space(E) space(C);
    }

    @include media-breakpoint-up(lg) {
      gap: space(D) space(B);
    }
  }

  &__logo {
    html.has-hover & {
      opacity: .7;
    }

    html.has-hover &:hover {
      opacity: 1;
    }

    .image {
      .image__wrapper {
        padding-top: 0 !important;
        height: auto;
      }

      img {
        height: rem(32);
        max-width: none;
        width: auto;
        position: static;

        @include media-breakpoint-up(md) {
          height: rem(40);
        }
      }
    }
  }
}
