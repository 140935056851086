@mixin text-link {
  $root: 'a:not(.btn):not(.download-link):not(.external-link)';

  #{$root} {
    color: var(--c-text);
    text-decoration: none;

    background-image: linear-gradient(to right, var(--c-link), var(--c-link));
    background-repeat: no-repeat;
    background-size: 100% 1px;
    background-position: 0 100%;

    transition: $transition-base;

    html.has-hover &:hover,
    &:focus {
      background-size: 100% 2px;
    }

    html.no-js & {
      word-wrap: break-word;
    }
  }
}
