.spam-protection {
  &--at:before {
    content: '@';
  }

  &--dot:before {
    content: '.';
  }

  &__inner {
    display: inline-block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
}