.page-intro {

  $root: &;

  position: relative;
  overflow: hidden;

  &:not(#{$root}--with-image#{$root}--image-as-bg) {
    .row {
      @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
      }
    }

    padding-top: space(D);

    @include media-breakpoint-up(md) {
      padding-top: space(B);
    }

    @include media-breakpoint-up(lg) {
      padding-top: space(A);
    }
  }

  &__text {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;

    .text-s {
      margin-bottom: space(I);
    }

    h1 {
      margin: 0;
    }

    .richtext {
      margin-top: space(I);
      max-width: rem(760);
    }
  }

  &__button {
    position: relative;
    z-index: 1;
    align-self: flex-end;

    margin-top: space(G);

    @include media-breakpoint-up(lg) {
      text-align: right;
    }
  }

  &__image {
    margin-top: space(F);

    @include media-breakpoint-up(md) {
      margin-top: space(D);
    }

    @include media-breakpoint-up(lg) {
      margin-top: space(C);
    }

    #{$root}:not(#{$root}--with-image):not(#{$root}--image-as-bg) & {
      height: 1px;
      background: $c-grey;
    }

    #{$root}--with-image#{$root}--image-as-bg & .image {
      margin: 0;

      * {
        padding-top: 0 !important;
      }
      .image__wrapper {
        position: static;
      }

      img {
        position: absolute;
      }
    }
  }

  &--with-image#{$root}--image-as-bg {

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--c-overlay);
    }

    .container-fluid {
      padding-top: space(D) !important;
      padding-bottom: space(D) !important;
    }

    .container-fluid > .row {
      align-content: center;
      text-align: center;

      min-height: rem(300) - 2 * space(D);

      @include media-breakpoint-up(md) {
        min-height: rem(400) - 2 * space(D);
      }

      @include media-breakpoint-up(lg) {
        min-height: rem(500) - 2 * space(D);
      }
    }

    #{$root}__button {
      text-align: center;
    }

    &,
    h1,
    button,
    a {
      color: $c-white;
    }

    .richtext {
      margin-left: auto;
      margin-right: auto;
    }

    #{$root}__image {
      margin: 0;
    }
  }
}
