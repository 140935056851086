.select-field {
  .form-group__icon-wrapper {
    position: relative;

    .icon,
    button {
      position: absolute;
      top: 50%;
      left: rem(10);
      width: rem(24);
      height: rem(24);
      transform: translateY(-50%);
      pointer-events: none;
    }

    button {
      pointer-events: all;
      border: 0;
      padding: 0;
      border-radius: 0;
      background: transparent;

      .icon {
        position: static;
        width: 100%;
        height: 100%;
        transform: none;
        display: block;
      }

      &.focus-visible {
        outline: 2px solid $c-black;
      }
    }

    select {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:not(.form-group__icon-wrapper--icon-right) select {
      padding-left: rem(42);
    }

    &--icon-right {
      select {
        padding-right: rem(42);
      }

      .icon,
      button {
        left: auto;
        right: rem(10);
      }
    }
  }
}
