$c-black: #000;
$c-grey: #C8C8C8;
$c-grey-15: transparentize($c-grey, .85);
$c-grey-40: transparentize($c-grey, .6);
$c-white: #FFF;
$c-error: #D90F30;

$c-ep-red: #981E32;
$c-ep-blue: #3B8FD7;
$c-eppg-blue: #42ADE2;
$c-eppg-dark-blue: #025589;
$c-euroment-violet: #762645;
$c-hoeltken-blue: #003B64;
$c-hoeltken-lime: #97C21A;
$c-mp-blue: #009CE1;
$c-mps-blue: #1C3E8E;

$ep-brand-colors: (
  "ep-red": $c-ep-red,
  "ep-blue": $c-ep-blue,
  "eppg-blue": $c-eppg-blue,
  "eppg-dark-blue": $c-eppg-dark-blue,
  "euroment-violet": $c-euroment-violet,
  "hoeltken-blue": $c-hoeltken-blue,
  "mp-blue": $c-mp-blue,
  "mps-blue": $c-mps-blue,
);

$ep-colors: (
	"black": $c-black,
	"grey": $c-grey,
	"grey-15": $c-grey-15,
	"grey-40": $c-grey-40,
	"white": $c-white,
	"error": $c-error,
);

$primary: orange; // override test: if orange visible we have a mistake
