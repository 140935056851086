@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=4866381b-ab01-4f2b-b392-7380effe2ca7&fontids=5760839,5760855,5760859");

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/helvetica/5760855/8b0e45aa-7a27-4583-b270-88f0e3551fa7.eot?#iefix");
  src: url("../fonts/helvetica/5760855/8b0e45aa-7a27-4583-b270-88f0e3551fa7.eot?#iefix") format("eot"), url("../fonts/helvetica/5760855/8d8e92a7-bfd4-4de7-bc65-45be2306bf81.woff2") format("woff2"), url("../fonts/helvetica/5760855/858c1d6a-cfc6-4367-91da-cd8209dcdc6e.woff") format("woff"), url("../fonts/helvetica/5760855/11cc70a4-497f-424a-adb3-beefd472055f.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/helvetica/5760839/93aa2f3f-e4ed-482d-bea4-bc24dc3a5c25.eot?#iefix");
  src: url("../fonts/helvetica/5760839/93aa2f3f-e4ed-482d-bea4-bc24dc3a5c25.eot?#iefix") format("eot"), url("../fonts/helvetica/5760839/aa38329d-5165-4fb4-82c8-fa97778b7cbd.woff2") format("woff2"), url("../fonts/helvetica/5760839/7a5a445e-de3f-4690-9002-552575769c45.woff") format("woff"), url("../fonts/helvetica/5760839/bb69e364-2b9f-4905-a3a0-6970caaa2a1f.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/helvetica/5760859/aa90ecf3-daa8-493f-b39e-3d6701e4e33c.eot?#iefix");
  src: url("../fonts/helvetica/5760859/aa90ecf3-daa8-493f-b39e-3d6701e4e33c.eot?#iefix") format("eot"), url("../fonts/helvetica/5760859/10d97c98-c7f0-4958-b294-92319d027783.woff2") format("woff2"), url("../fonts/helvetica/5760859/e4caf272-7860-4dd4-9768-8ecc229ce7c3.woff") format("woff"), url("../fonts/helvetica/5760859/7e2acf07-ae90-4c1e-9b47-ece6c7c914cd.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
