.line-teaser {
  &__line {
    display: none;

    @at-root .theme-eppm & {
      display: block;
      position: absolute;
      left: -10%;
      bottom: rem(-26);
      width: 120%;
      z-index: 1;

      svg {
        width: 100%;
        height: auto;

        path {
          transition: stroke-dashoffset 1800ms cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-delay: 1000ms;
          stroke-dashoffset: 1357px;

          @media (prefers-reduced-motion: reduce) {
            stroke-dashoffset: 0;
          }
        }
      }

      &.js-visible,
      .no-js & {
        svg path {
          stroke-dashoffset: 0;
        }
      }
    }
  }

  &__teasers {
    @include make-row(rem(16));
    position: relative;

    &.js-visible,
    .no-js & {
      .line-teaser__teaser {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &__teaser {
    @include make-col-ready(rem(16));
    @include make-col(1, 2);
    transition: 1200ms transform ease-out, 1200ms opacity ease-out;
    opacity: 0;
    transform: translateY(rem(40));

    &--first {
      margin-top: space(G);
      z-index: 2;
    }

    &--second {
      transition-delay: 600ms;
    }

    &-inner {
      position: relative;

      .line-teaser__eppg-lines {
        display: none;

        @at-root .theme-eppg & {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;

          svg {
            color: var(--c-accent);
            max-width: 100%;
            height: auto;
          }
        }
      }

      @at-root .theme-eppg .line-teaser__teaser--first & .line-teaser__eppg-lines {
        width: 175%;
        transform: translate(rem(18), rem(29));

        @include media-breakpoint-up(sm) {
          width: 100%;
          transform: translate(rem(-38), rem(31));
        }

        @include media-breakpoint-up(lg) {
          transform: translate(rem(-57), rem(48));
        }
      }

      @at-root .theme-eppg .line-teaser__teaser--second {
        z-index: 10;
      }

      @at-root .theme-eppg .line-teaser__teaser--second & .line-teaser__eppg-lines {
        display: none;

        @include media-breakpoint-up(sm) {
          display: block;
          transform: translate(rem(35), rem(-33));
        }

        @include media-breakpoint-up(lg) {
          transform: translate(rem(56), rem(-48));
        }
      }
    }

    &-text {
      transition: $transition-base;

      .display-l {
        margin-bottom: space(H);

        &:last-child {
          margin-bottom: 0;
        }
      }

      &--mobile {
        margin-top: space(D);
      }

      &--desktop {
        --c-headline: #{$c-white};
        --c-text: #{$c-white};
        --c-link: #{$c-white};

        display: none;
        padding: space(G);
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  @include media-breakpoint-up(md) {
    @at-root .theme-eppm &__line {
      left: 0;
      bottom: rem(-40);
      width: 100%;
    }

    &__teasers {
      @include make-row(rem(24));

      @at-root .theme-eppm &:before {
        bottom: rem(-46);
        left: 0;
        width: 100%;
        padding-top: 26.2%;
      }
    }

    &__teaser {
      @include make-col-ready(rem(24));
      @include make-col(5);

      &--first {
        @include make-col-offset(1);
        margin-top: space(D);
      }

      .image {
        transition: $transition-base;
      }

      &-text {
        &--mobile {
          display: none;
        }

        &--desktop {
          display: flex;
        }

        .txt-btn {
          font-size: rem(16);
        }
      }

      &[data-href]:hover {
        .image {
          transform: translateY(rem(-8));
        }

        .line-teaser__teaser-text {
          transform: translateY(rem(-12));

          .txt-btn {
            transform: translateY(-2px);

            span {
              background-size: 100% 3px;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    @at-root .theme-eppm &__line {
      left: 10%;
      bottom: rem(-64);
      width: 80%;
    }

    &__teasers {
      @at-root .theme-eppm &:before {
        bottom: rem(-70);
        left: 10%;
        width: 80%;
        padding-top: 20.96%;
      }
    }

    &__teaser {
      @include make-col(4);

      &--first {
        @include make-col-offset(2);
        margin-top: space(C);
      }
    }
  }
}
