@mixin ep-button-styles() {
	@extend %btn;
	@include f-helvetica-bold;

	display: inline-block;
  text-align: left;
  border: none;
  border-radius: 2px;

  padding: em(21) em(32) em(19);

  transition: $transition-base;
  text-decoration: none;
  cursor: pointer;

  background-color: var(--c-link);
  color: $c-white;

  .icon {
    width: em(24, 16);
    height: em(24, 16);

    margin-top: em(-12, 16);
    margin-bottom: em(-12, 16);

    fill: currentColor;

    &:first-child {
      margin-right: space(I);
    }

    &:last-child {
      margin-left: space(I);
    }
  }

  &:hover,
  &.focus-visible {
    text-decoration: none;
    box-shadow: none;

    transform: translateY(-2px);
  }

  &.focus-visible {
    outline: 2px solid $c-black;
  }
}
