.btn {
  @include ep-button-styles();
  color: $c-white;
  background: var(--c-link);

  &--outline {
    color: var(--c-text);
    background: $c-white;
    border: rem(1) solid var(--c-divider-dark);
  }
}
