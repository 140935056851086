.text-box {

  $root: &;


  &__content {

    #{$root}--bg-white & {
      background: $c-white;
    }

    #{$root}--bg-light & {
      background: var(--c-bg-light);
    }

    #{$root}--bg-dark & {
      --c-headline: #{$c-white};
      --c-text: #{$c-white};
      background: var(--c-bg-dark);
      color: var(--c-text);
    }
  }

  &__copy {
    padding: space(G) space(H);

    @include media-breakpoint-up(md) {
      padding: space(E);
    }
  }

  &__copy-big {
    padding: space(G) space(H);

    @include media-breakpoint-up(md) {
      padding: space(E);
    }

    @include media-breakpoint-up(lg) {
      display: inline-flex;
      padding: space(C);
      flex: 0 0 70%;
      width: 70%;
    }

    .left & {
      flex: 0 0 70%;
      width: 70%;
    }

    .middle & {
      flex: 0 0 50%;
      width: 50%;
    }

    .right & {
      flex: 0 0 30%;
      width: 30%;
    }
  }

  &__copy-small {
    padding-top: space(G);
    padding-bottom: space(G);

    @include media-breakpoint-up(md) {
      padding-top: space(E);
      padding-bottom: space(E);
    }

    @include media-breakpoint-up(lg) {
      padding-top: space(C);
      padding-bottom: space(C);
    }

    &-content {
      display: inline-flex;
      padding-left: space(H);
      padding-right: space(H);

      @include media-breakpoint-up(md) {
        padding-left: space(E);
        padding-right: space(E);
      }

      @include media-breakpoint-up(lg) {
        padding-left: space(G);
        padding-right: space(D);
      }

      // small bar
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: -1 * space(G);
        height: rem(1);
        left: space(H);
        right: space(H);

        @include media-breakpoint-up(md) {
          top: -1 * space(E);
          left: space(E);
          right: space(E);
        }

        @include media-breakpoint-up(lg) {
          right: auto;
          left: 0;
          top: 0;
          width: rem(1);
          height: 100%;
        }

        background: var(--c-divider-dark);

        #{$root}--bg-dark & {
          background: var(--c-divider-light);
        }
      }
    }

    &-image {
      width: space(E);
      flex: 0 0 space(E);
      margin-right: space(H);
    }

    &-text {
      flex: 1 1 auto;
    }
  }
}
