.text-image {

  $root: &;

  &__content {
    @include make-row();
    @include media-breakpoint-up(lg) {
      align-items: flex-start;
    }
  }

  &__image {
    figure {
      margin: 0;
    }
  }

  &__text {
    margin-top: space(F);

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  &__eppg-lines {
    display: none;
  }

  //
  //  Modifier
  //
  &--image-right {
    @include media-breakpoint-up(md) {
      #{$root}__image {
        order: 1;
      }
      #{$root}__text {
        order: -1;
      }
    }
  }

  &--image-centered {
    @include media-breakpoint-up(md) {
      #{$root}__content {
        align-items: center;
      }
    }
  }

  &--line-pattern {
    .image {
      margin-top: rem(29);
      margin-bottom: rem(29);
      position: relative;

      @include media-breakpoint-up(md) {
        margin: rem(35) rem(-35) rem(35) rem(35);
      }

      @include media-breakpoint-up(lg) {
        margin: rem(52) rem(-52) rem(52) rem(52);
      }
    }

    .text-image__eppg-lines {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 70%;

      svg {
        color: var(--c-accent);
        max-width: 100%;
        height: auto;
      }

      @include media-breakpoint-up(md) {
        left: rem(12);
        width: 80%;
        height: 100%;
      }
    }

    &.text-image--image-right {
      .image {
        @include media-breakpoint-up(md) {
          margin-left: rem(-35);
          margin-right: rem(35);
        }

        @include media-breakpoint-up(lg) {
          margin-left: rem(-52);
          margin-right: rem(52);
        }
      }

      .text-image__eppg-lines {
        @include media-breakpoint-up(md) {
          left: auto;
          right: rem(12);
        }
      }
    }
  }
}
