.product-detail {
  padding-top: space(D);

  @include media-breakpoint-up(md) {
    padding-top: space(B);
  }

  @include media-breakpoint-up(lg) {
    padding-top: space(A);
  }

  &__intro {
    .richtext {
      @extend %display-s;
      max-width: rem(760);
      margin-bottom: space(G);
    }

    .image {
      text-align: left;
    }

    hr {
      display: none;
      height: 1px;
      background: var(--c-divider-dark);
      border: none;
    }

    @include media-breakpoint-up(md) {
      .richtext {
        margin-bottom: 0;
      }

      .image {
        text-align: right;
      }

      hr {
        display: block;
        margin-top: space(D);
      }
    }

    @include media-breakpoint-up(lg) {
      hr {
        margin-top: space(C);
      }
    }
  }

  &__details {
    margin-top: space(D);

    > h2 {
      @extend %display-m;
      margin-bottom: space(H);
    }

    > div:not(:last-child) {
      margin-bottom: space(E);
    }

    @include media-breakpoint-up(md) {
      @include make-row();
      margin-top: space(B);

      > h2 {
        @include make-col-ready();
        @include make-col(4);
      }

      > div {
        @include make-col-ready();
        @include make-col(8);

        &:not(:last-child) {
          margin-bottom: space(D);
        }
      }
    }

    @include media-breakpoint-up(lg) {
      margin-top: space(A);

      > h2 {
        @include make-col(3);
      }

      > div {
        @include make-col(9);

        &:not(:last-child) {
          margin-bottom: space(C);
        }
      }
    }
  }
}
