@use "sass:math";

//
//  Form and Input Styling
//  dependency: bootstrap/scss/_forms
//


/*
* Bootstrap overrides
*/

// change input background from grey to white on non-white container backgrounds
// @each $color in $ep-colors {
//   @if nth($color, 1) != $c-white {
//     .bg-#{nth($color, 1)} {
//       .form-control {
//         background-color: $c-white;
//         border-color: $c-white; // TODO: test on non-white background (might override focus and invalid)
//       }

//       .checkbox label div::before,
//       .radio__input-wrapper div::before {
//         background-color: $c-white;
//       }

//       select.form-control {
//         &:focus::-ms-value {
//           background-color: $c-white;
//         }
//       }
//     }
//   }
// }
// @each $color in $ah-theme-colors {
//   .bg-#{nth($color, 1)} {
//     .form-control {
//       background-color: $c-white;
//       border-color: $c-white; // TODO: test on non-white background (might override focus and invalid)
//     }

//     .checkbox label div::before,
//     .radio__input-wrapper div::before {
//       background-color: $c-white;
//     }

//     select.form-control {
//       &:focus::-ms-value {
//         background-color: $c-white;
//       }
//     }
//   }
// }

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;

  &:focus {
    box-shadow: none;
  }
}

.form-group label,
.form-group .form-text,
.invalid-feedback {
  @include f-helvetica-normal();
  font-size: rem(13);
  line-height: em(16, 13);
}

.form-control {
  background-clip: border-box;
  appearance: none; // remove iOS borders
  height: rem(64);
  padding: rem(20) rem(16);

  html.text-size-large & {
    height: auto;
  }

  &:focus {
    background-color: $c-white;
    outline: none;
    transition: $transition-base;
  }

  // &::-webkit-search-cancel-button {
  //   -webkit-appearance: none;
  //   background: inline-svg('close');
  //   background-size: cover;
  //   width: rem(24);
  //   height: rem(24);
  //   cursor: pointer;

  //   html.text-size-large & {
  //     width: rem(32);
  //     height: rem(32);
  //   }
  // }

  html.has-hover, &.focus-visible {

    &::-webkit-search-cancel-button {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    &,
    &::-webkit-input-placeholder {
      color: transparentize($c-black, .8) !important;
    }
  }

  &--small {
    height: rem(48);
    padding-bottom: rem(12);
    padding-top: rem(12);
  }
}

select.form-control {
  background-image: inline-svg('chevron-down', $c-black);
  background-position: right rem(16) center;
  background-repeat: no-repeat;
  padding-right: rem(40) !important;
}

.form-group {

  > label {
    display: block;
    margin: 0;

    + * {
      margin-top: space(I);
    }
  }

  &.has-error {
    label {
      color: $c-error;
    }

    input, textarea, select {
      border-color: $c-error;
    }

    .invalid-feedback {
      @extend %text-s;
      display: block;
    }
  }

  &.text-field,
  &.text-area,
  &.select-field {
    .invalid-feedback {
      color: $c-error;
    }
  }

  &.text-field,
  &.select-field {

    &.has-label {
      .form-control {
        padding: rem(32) rem(16) rem(8);
      }

      // Floating Label
      label {
        @extend %text-m;

        position: relative;

        span {
          position: absolute;
          top: rem(21);
          left: rem(14);
          transform: translateY(0) scale(1);
          transform-origin: 0 50%;
          transition: $transition-base;

          color: #757575;
          pointer-events: none;
        }

        .has-value + span {
          $frac: math.div(13, 16);
          transform: translateX(rem(3)) translateY(#{-1 * rem(14)}) scale(#{$frac});
        }
      }
    }

    textarea {
      height: rem(160);
    }
  }

  &.text-field {
    &.has-label {
      label {
        :focus + span {
          $frac: math.div(13, 16);
          transform: translateX(rem(3)) translateY(#{-1 * rem(14)}) scale(#{$frac});
        }
      }
    }
  }

  &.text-area {

    .form-control {
      padding: rem(14) rem(16);
      height: rem(160);
    }

    // Floating Label
    label {
      @extend %text-m;

      position: relative;

      span {
        position: absolute;
        top: rem(18);
        left: rem(16);
        transform: translateY(0) scale(1);
        transform-origin: 0 50%;

        color: #757575;
        pointer-events: none;
      }

      :focus + span,
      .has-value + span {
        display: none;
      }
    }
  }
}
