@charset "UTF-8";
@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=4866381b-ab01-4f2b-b392-7380effe2ca7&fontids=5760839,5760855,5760859");
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/helvetica/5760855/8b0e45aa-7a27-4583-b270-88f0e3551fa7.eot?#iefix");
  src: url("../fonts/helvetica/5760855/8b0e45aa-7a27-4583-b270-88f0e3551fa7.eot?#iefix") format("eot"), url("../fonts/helvetica/5760855/8d8e92a7-bfd4-4de7-bc65-45be2306bf81.woff2") format("woff2"), url("../fonts/helvetica/5760855/858c1d6a-cfc6-4367-91da-cd8209dcdc6e.woff") format("woff"), url("../fonts/helvetica/5760855/11cc70a4-497f-424a-adb3-beefd472055f.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/helvetica/5760839/93aa2f3f-e4ed-482d-bea4-bc24dc3a5c25.eot?#iefix");
  src: url("../fonts/helvetica/5760839/93aa2f3f-e4ed-482d-bea4-bc24dc3a5c25.eot?#iefix") format("eot"), url("../fonts/helvetica/5760839/aa38329d-5165-4fb4-82c8-fa97778b7cbd.woff2") format("woff2"), url("../fonts/helvetica/5760839/7a5a445e-de3f-4690-9002-552575769c45.woff") format("woff"), url("../fonts/helvetica/5760839/bb69e364-2b9f-4905-a3a0-6970caaa2a1f.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/helvetica/5760859/aa90ecf3-daa8-493f-b39e-3d6701e4e33c.eot?#iefix");
  src: url("../fonts/helvetica/5760859/aa90ecf3-daa8-493f-b39e-3d6701e4e33c.eot?#iefix") format("eot"), url("../fonts/helvetica/5760859/10d97c98-c7f0-4958-b294-92319d027783.woff2") format("woff2"), url("../fonts/helvetica/5760859/e4caf272-7860-4dd4-9768-8ecc229ce7c3.woff") format("woff"), url("../fonts/helvetica/5760859/7e2acf07-ae90-4c1e-9b47-ece6c7c914cd.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
/*!
 * Bootstrap Grid v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
/*!
 * Bootstrap Reboot v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--c-link);
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: var(--c-link);
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.4em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  background-color: #F8F8F8;
  background-clip: padding-box;
  border: 1px solid #999;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.form-control:focus {
  color: #000;
  background-color: #F8F8F8;
  border-color: var(--c-accent);
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: rgba(0, 0, 0, 0.6);
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #000;
  background-color: #F8F8F8;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.4;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.4;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 2px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.4em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.35em + 0.1875rem) center;
  background-size: calc(0.7em + 0.375rem) calc(0.7em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.4em + 0.75rem);
  background-position: top calc(0.35em + 0.1875rem) right calc(0.35em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #F8F8F8 no-repeat center right 1.75rem/calc(0.7em + 0.375rem) calc(0.7em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #D90F30;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #fff;
  background-color: rgba(217, 15, 48, 0.9);
  border-radius: 2px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #D90F30;
  padding-right: calc(1.4em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D90F30' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D90F30' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.35em + 0.1875rem) center;
  background-size: calc(0.7em + 0.375rem) calc(0.7em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #D90F30;
  box-shadow: 0 0 0 0.2rem rgba(217, 15, 48, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.4em + 0.75rem);
  background-position: top calc(0.35em + 0.1875rem) right calc(0.35em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #D90F30;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D90F30' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D90F30' stroke='none'/%3e%3c/svg%3e") #F8F8F8 no-repeat center right 1.75rem/calc(0.7em + 0.375rem) calc(0.7em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #D90F30;
  box-shadow: 0 0 0 0.2rem rgba(217, 15, 48, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #D90F30;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #D90F30;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #D90F30;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f02b4b;
  background-color: #f02b4b;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(217, 15, 48, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #D90F30;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #D90F30;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #D90F30;
  box-shadow: 0 0 0 0.2rem rgba(217, 15, 48, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: orange !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #b37400 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #000 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item, .embed-responsive [data-type=placeholder],
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714286%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

a:hover {
  text-decoration: none;
}

.display-xxl,
.richtext .display-xxl {
  font-weight: 700;
  line-height: 1.1;
  font-size: 2.5rem;
  color: var(--c-headline);
}
@media (min-width: 768px) {
  .display-xxl,
.richtext .display-xxl {
    font-size: 3.125rem;
  }
}
@media (min-width: 992px) {
  .display-xxl,
.richtext .display-xxl {
    font-size: 4.375rem;
  }
}

.display-xl,
.richtext .display-xl {
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.625rem;
  color: var(--c-headline);
}
@media (min-width: 768px) {
  .display-xl,
.richtext .display-xl {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .display-xl,
.richtext .display-xl {
    font-size: 2.5625rem;
  }
}

.navigation__logo, .display-l,
.richtext .display-l {
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.4357rem;
  color: var(--c-headline);
}
@media (min-width: 992px) {
  .navigation__logo, .display-l,
.richtext .display-l {
    font-size: 2rem;
  }
}

.product-detail__details > h2, .image-teaser__entry-headline, .display-m,
.richtext .display-m {
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.125rem;
  color: var(--c-headline);
}
@media (min-width: 992px) {
  .product-detail__details > h2, .image-teaser__entry-headline, .display-m,
.richtext .display-m {
    font-size: 1.4357rem;
  }
}

.quotes__slide .quotes__quote, .product-detail__intro .richtext, .display-s,
.richtext .display-s {
  font-weight: 300;
  line-height: 1.2;
  font-size: 1.125rem;
}
@media (min-width: 992px) {
  .quotes__slide .quotes__quote, .product-detail__intro .richtext, .display-s,
.richtext .display-s {
    font-size: 1.4357rem;
  }
}

.quotes__slide .quotes__author, .pagination__link a, .form-group.text-area label, .form-group.text-field.has-label label, .form-group.select-field.has-label label, .text-m,
.richtext .text-m, body {
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
}

.form-group.has-error .invalid-feedback, .text-s,
.richtext .text-s {
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.875rem;
}

.btn, .text-btn,
.richtext .text-btn {
  font-weight: 700;
  line-height: 1.5;
  font-size: 1rem;
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 768px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 992px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 32px;
    padding-right: 32px;
    max-width: 1240px;
    margin: 0 auto;
  }
}
html.text-size-large .container-fluid, html.text-size-large .container-sm, html.text-size-large .container-md, html.text-size-large .container-lg, html.text-size-large .container-xl {
  max-width: none;
}

.svg-ie-wrapper {
  position: relative;
  height: 0;
}
.svg-ie-wrapper svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

:root {
  --color-alpha: 1;
}

button {
  color: var(--c-link);
}

body,
.theme-eppm {
  --c-text: #000;
  --c-headline: #000;
  --c-link: #3B8FD7;
  --c-accent: #981E32;
  --c-accent-10: rgba(59, 143, 215, 0.1);
  --c-cta: linear-gradient(170deg, #800e06 0%, #a22c57 70%);
  --c-bg-light: rgba(200, 200, 200, 0.15);
  --c-bg-dark: #000;
  --c-divider-light: rgba(200, 200, 200, 0.4);
  --c-divider-dark: #C8C8C8;
  --c-footer: #000;
  --c-overlay: rgba(0,0,0,.15);
}

.theme-epem {
  --c-text: #000;
  --c-headline: #000;
  --c-link: #3B8FD7;
  --c-accent: #3B8FD7;
  --c-accent-10: rgba(59, 143, 215, 0.1);
  --c-cta: #981E32;
  --c-bg-light: rgba(200, 200, 200, 0.15);
  --c-bg-dark: #000;
  --c-divider-light: rgba(200, 200, 200, 0.4);
  --c-divider-dark: #C8C8C8;
  --c-footer: #000;
  --c-overlay: rgba(0,0,0,.15);
}

.theme-eppg {
  --c-text: #000;
  --c-headline: #000;
  --c-link: #42ADE2;
  --c-accent: #981E32;
  --c-accent-10: rgba(152, 30, 50, 0.1);
  --c-cta: linear-gradient(60deg, #002A45 0%, #024C74 100%);
  --c-bg-light: rgba(200, 200, 200, 0.15);
  --c-bg-dark: #981E32;
  --c-divider-light: rgba(200, 200, 200, 0.4);
  --c-divider-dark: #C8C8C8;
  --c-footer: #981E32;
  --c-overlay: rgba(0,0,0,.5);
}

.theme-euroment {
  --c-text: #000;
  --c-headline: #762645;
  --c-link: #762645;
  --c-accent: #762645;
  --c-accent-10: rgba(118, 38, 69, 0.1);
  --c-cta: #762645;
  --c-bg-light: rgba(200, 200, 200, 0.15);
  --c-bg-dark: #000;
  --c-divider-light: rgba(200, 200, 200, 0.4);
  --c-divider-dark: #C8C8C8;
  --c-footer: #762645;
  --c-overlay: rgba(8,20,45,.45);
}

.theme-hoeltken {
  --c-text: #000;
  --c-headline: #003B64;
  --c-link: #003B64;
  --c-accent: #003B64;
  --c-accent-10: rgba(0, 59, 100, 0.1);
  --c-cta: #97C21A;
  --c-bg-light: rgba(200, 200, 200, 0.15);
  --c-bg-dark: #003B64;
  --c-divider-light: rgba(200, 200, 200, 0.4);
  --c-divider-dark: #C8C8C8;
  --c-footer: #003B64;
  --c-overlay: rgba(8,20,45,.45);
}

.theme-mp {
  --c-text: #000;
  --c-headline: #009CE1;
  --c-link: #009CE1;
  --c-accent: #009CE1;
  --c-accent-10: rgba(0, 156, 225, 0.1);
  --c-cta: #009CE1;
  --c-bg-light: rgba(200, 200, 200, 0.15);
  --c-bg-dark: #009CE1;
  --c-divider-light: rgba(200, 200, 200, 0.4);
  --c-divider-dark: #C8C8C8;
  --c-footer: #009CE1;
  --c-overlay: rgba(0,0,0,.15);
}

.theme-mps {
  --c-text: #000;
  --c-headline: #1C3E8E;
  --c-link: #1C3E8E;
  --c-accent: #1C3E8E;
  --c-accent-10: rgba(28, 62, 142, 0.1);
  --c-cta: #1C3E8E;
  --c-bg-light: rgba(200, 200, 200, 0.15);
  --c-bg-dark: #1C3E8E;
  --c-divider-light: rgba(200, 200, 200, 0.4);
  --c-divider-dark: #C8C8C8;
  --c-footer: #1C3E8E;
  --c-overlay: rgba(8,20,45,.45);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.hyphenate {
  hyphens: auto;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.image {
  margin: 0;
  font-size: 0;
}
.image span {
  display: inline-block;
  max-width: 100%;
}
.image__wrapper {
  height: 0;
  position: relative;
  overflow: hidden;
  font-size: 0;
}
.image__wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image__wrapper::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.image figcaption {
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
}

img {
  max-width: 100%;
  height: auto;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

/*
*   Module Spacings
*/
.ep-module + .ep-module,
.footer {
  margin-top: 3rem;
}
@media (min-width: 768px) {
  .ep-module + .ep-module,
.footer {
    margin-top: 5rem;
  }
}
@media (min-width: 992px) {
  .ep-module + .ep-module,
.footer {
    margin-top: 6.5rem;
  }
}

.ep-module--with-bg:last-child {
  margin-bottom: -3rem;
}
@media (min-width: 768px) {
  .ep-module--with-bg:last-child {
    margin-bottom: -5rem;
  }
}
@media (min-width: 992px) {
  .ep-module--with-bg:last-child {
    margin-bottom: -6.5rem;
  }
}

.ep-module--with-bg + .ep-module--with-bg {
  margin-top: 0;
}

.preview main {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 768px) {
  .preview main {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media (min-width: 992px) {
  .preview main {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
}

/*
 *  Text Spacings (ordered smalles spacing first)
 */
.richtext .text-m,
.richtext p,
.richtext h1,
.richtext h2,
.richtext h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.richtext .display-s {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .richtext .display-s {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.richtext .display-xl + .display-s {
  margin-top: -1.5rem;
}
.richtext .display-l + .display-s,
.richtext .display-m + .display-s {
  margin-top: -1rem;
}
@media (min-width: 768px) {
  .richtext .display-xl + .display-s {
    margin-top: -1rem;
  }
}
@media (min-width: 992px) {
  .richtext .display-l + .display-s {
    margin-top: -1rem;
  }
  .richtext .display-m + .display-s {
    margin-top: -1.5rem;
  }
}
.richtext .display-m {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .richtext .display-m {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
}
.richtext .display-l {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .richtext .display-l {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
}
.richtext .display-xl {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .richtext .display-xl {
    margin-top: 2.5rem;
  }
}
.richtext > :first-child {
  margin-top: 0;
}
.richtext > :last-child {
  margin-bottom: 0;
}

.bg-black {
  --c-text: #FFF;
  color: var(--c-text);
}

.bg-grey,
.bg-white,
.bg-default {
  color: var(--c-text);
}

.richtext > *, .richtext {
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
}
.richtext * {
  margin: 0;
}
.richtext p a:not(.btn):not(.download-link):not(.external-link), .richtext ul a:not(.btn):not(.download-link):not(.external-link), .richtext ol a:not(.btn):not(.download-link):not(.external-link) {
  color: var(--c-text);
  text-decoration: none;
  background-image: linear-gradient(to right, var(--c-link), var(--c-link));
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: 0 100%;
  transition: all 0.2s ease;
}
html.has-hover .richtext p a:not(.btn):not(.download-link):not(.external-link):hover, .richtext p a:not(.btn):not(.download-link):not(.external-link):focus, html.has-hover .richtext ul a:not(.btn):not(.download-link):not(.external-link):hover, .richtext ul a:not(.btn):not(.download-link):not(.external-link):focus, html.has-hover .richtext ol a:not(.btn):not(.download-link):not(.external-link):hover, .richtext ol a:not(.btn):not(.download-link):not(.external-link):focus {
  background-size: 100% 2px;
}
html.no-js .richtext p a:not(.btn):not(.download-link):not(.external-link), html.no-js .richtext ul a:not(.btn):not(.download-link):not(.external-link), html.no-js .richtext ol a:not(.btn):not(.download-link):not(.external-link) {
  word-wrap: break-word;
}
.richtext b, .richtext strong {
  font-weight: 700;
}
.richtext i, .richtext em {
  font-style: italic;
}
.richtext sub,
.richtext sup {
  font-size: 0.75rem;
  line-height: 1.25em;
  margin-left: 0.0625em;
}
.richtext sub {
  bottom: -0.25em;
}
.richtext sup {
  top: -0.5em;
}
.richtext ol {
  counter-reset: ol-counter;
  list-style: none;
  padding-left: 2.5rem;
}
.richtext ol li {
  counter-increment: ol-counter;
  position: relative;
}
.richtext ol li::before {
  content: counter(ol-counter) ".";
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: 0.3125rem;
}
.richtext ul {
  padding-left: 2.5rem;
}
.richtext ol li, .richtext ul li {
  margin: 0;
}
.richtext ol li + li, .richtext ul li + li {
  margin-top: 0.5rem;
}
.richtext blockquote {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.2;
}
.richtext .txt-btn,
.richtext .download-link,
.richtext .external-link {
  margin-bottom: 1rem;
}
.richtext .table-responsive {
  overflow: auto;
}
.richtext table {
  font-size: 1rem;
  line-height: 1.375em;
  overflow: hidden;
}
.richtext table td, .richtext table th {
  padding-bottom: 1.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.richtext table td:first-child, .richtext table th:first-child {
  padding-left: 0;
}
.richtext table td:last-child, .richtext table th:last-child {
  padding-right: 0;
}
.richtext table.full-width {
  width: 100%;
}
.richtext figure {
  margin: 1.5rem 0;
}
.richtext > :first-child,
.richtext > :first-child > :first-child,
.richtext > :first-child > :first-child > :first-child {
  margin-top: 0;
}
.richtext > :last-child,
.richtext > :last-child > :last-child,
.richtext > :last-child > :last-child > :last-child {
  margin-bottom: 0;
}

body {
  font-weight: 400;
  color: #000;
  color: var(--c-text);
}
@media (min-width: 768px) {
  body {
    font-size: 1.125rem;
  }
}

.t-centered {
  text-align: center;
}

.t-right {
  text-align: right;
}

p {
  margin: 0;
}

.f-helvetica-light {
  font-weight: 300;
}

.f-helvetica-normal {
  font-weight: 400;
}

.f-helvetica-bold {
  font-weight: 700;
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  max-width: 1440px;
}

:root {
  --app-height: 100vh;
}

html {
  overflow: hidden auto;
}
html.locked {
  overflow: hidden;
}

html.text-size-large body > main,
html.text-size-large body > .content-wrapper {
  max-width: none;
}
body.preview > .content-wrapper {
  background: transparent;
  min-height: 100vh;
}

.spam-protection--at:before {
  content: "@";
}
.spam-protection--dot:before {
  content: ".";
}
.spam-protection__inner {
  display: inline-block;
  height: 0;
  overflow: hidden;
  width: 0;
}

.tns-outer {
  position: relative;
}
.tns-controls {
  display: none;
}
.tns-controls button {
  background: rgba(255, 255, 255, 0.5) url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNi45OTkgMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgN2MwLS4yMjguMDc3LS40NTUuMjMyLS42NGw1LTZhMSAxIDAgMSAxIDEuNTM2IDEuMjhMMi4yOTIgNy4wMWw0LjMxNSA1LjM2M2ExIDEgMCAwIDEtMS41NTggMS4yNTRsLTQuODI4LTZBMSAxIDAgMCAxIDAgNyIgc3R5bGU9ImZpbGw6I2ZmZiIvPjwvc3ZnPg==") no-repeat 0.9375rem 0.75rem;
  background-size: 0.4375rem 0.875rem;
  border: 0.0625rem solid #FFF;
  border-radius: 50%;
  color: transparent;
  height: 2.5rem;
  font: 0/0 a;
  padding: 0;
  position: absolute;
  top: calc(50% - 1.25rem);
  width: 2.5rem;
  z-index: 1;
}
.tns-controls button:first-child {
  left: 0.5rem;
}
.tns-controls button:last-child {
  right: 0.5rem;
  transform: rotate(180deg);
}
@media (min-width: 768px) {
  .tns-controls button:first-child {
    left: 2.5rem;
  }
  .tns-controls button:last-child {
    right: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .tns-controls button:first-child {
    left: 6.5rem;
  }
  .tns-controls button:last-child {
    right: 6.5rem;
  }
}
@media (min-width: 576px) {
  .tns-controls {
    display: block;
  }
}
.tns-nav {
  left: 0;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  z-index: 1;
}
.tns-nav button {
  background: none;
  border: none;
  height: 1.5rem;
  margin: 0 0.5rem;
  padding: 0;
  width: 1.5rem;
}
.tns-nav button:after {
  border: 0.0625rem solid #FFF;
  border-radius: 50%;
  content: "";
  display: block;
  height: 100%;
  transition: all 0.2s ease;
  width: 100%;
}
.tns-nav button.tns-nav-active:after {
  background: var(--c-accent);
  border-width: 0.5rem;
}
@media (min-width: 992px) {
  .tns-nav {
    bottom: 2rem;
  }
}

/*
We declare all variables here as default so that they are easy to override...
*/
/* Border, shadows, ... */
/*
Variables that begin with $var- should NEVER be used directly in CSS rules.
they should only be included via the "@include var(property, var-name)"
mechanism.
*/
/* Border styles */
/* Cookie notice positioning */
/* Text colors */
/* Font Families */
/* White */
/* Differently shaded colors */
/*
Use @include var(property, varname) to include a variable, e.g.

    @include var(background-color, white1);

to create a white background.
*/
/* Spacing */
/* Breakpoints */
.klaro {
  font-family: inherit;
  font-family: var(--font-family, inherit);
  font-size: 14px;
  font-size: var(--font-size, 14px);
}
.klaro button {
  font-family: inherit;
  font-family: var(--font-family, inherit);
  font-size: 14px;
  font-size: var(--font-size, 14px);
}
.klaro.cm-as-context-notice {
  height: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
}
.klaro .cookie-modal,
.klaro .context-notice,
.klaro .cookie-notice {
  /* The switch - the box around the slider */
}
.klaro .cookie-modal .cm-switch-container,
.klaro .context-notice .cm-switch-container,
.klaro .cookie-notice .cm-switch-container {
  border-bottom-style: solid;
  border-bottom-style: var(--border-style, solid);
  border-bottom-width: 1px;
  border-bottom-width: var(--border-width, 1px);
  border-bottom-color: #c8c8c8;
  border-bottom-color: var(--light2, #c8c8c8);
  display: block;
  position: relative;
  padding: 10px;
  padding-left: 66px;
  line-height: 20px;
  vertical-align: middle;
  min-height: 40px;
}
.klaro .cookie-modal .cm-switch-container:last-child,
.klaro .context-notice .cm-switch-container:last-child,
.klaro .cookie-notice .cm-switch-container:last-child {
  border-bottom: 0;
}
.klaro .cookie-modal .cm-switch-container:first-child,
.klaro .context-notice .cm-switch-container:first-child,
.klaro .cookie-notice .cm-switch-container:first-child {
  margin-top: 0;
}
.klaro .cookie-modal .cm-switch-container p,
.klaro .context-notice .cm-switch-container p,
.klaro .cookie-notice .cm-switch-container p {
  margin-top: 0;
}
.klaro .cookie-modal .cm-switch,
.klaro .context-notice .cm-switch,
.klaro .cookie-notice .cm-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}
.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
  background-color: #1a936f;
  background-color: var(--green1, #1a936f);
}
.klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider {
  background-color: #1a936f;
  background-color: var(--green1, #1a936f);
  opacity: 0.6;
}
.klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
  background-color: #24cc9a;
  background-color: var(--green2, #24cc9a);
  opacity: 0.8;
}
.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider::before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
.klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
  background-color: #24cc9a;
  background-color: var(--green2, #24cc9a);
  opacity: 0.8;
  cursor: not-allowed;
}
.klaro .cookie-modal .slider,
.klaro .context-notice .slider,
.klaro .cookie-notice .slider {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
}
.klaro .cookie-modal .cm-list-input,
.klaro .context-notice .cm-list-input,
.klaro .cookie-notice .cm-list-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 50px;
  height: 30px;
}
.klaro .cookie-modal .cm-list-title,
.klaro .context-notice .cm-list-title,
.klaro .cookie-notice .cm-list-title {
  font-size: 0.9em;
  font-weight: 600;
}
.klaro .cookie-modal .cm-list-description,
.klaro .context-notice .cm-list-description,
.klaro .cookie-notice .cm-list-description {
  color: #7c7c7c;
  color: var(--dark3, #7c7c7c);
  font-size: 0.9em;
  padding-top: 4px;
}
.klaro .cookie-modal .cm-list-label,
.klaro .context-notice .cm-list-label,
.klaro .cookie-notice .cm-list-label {
  /* The slider */
  /* Rounded sliders */
}
.klaro .cookie-modal .cm-list-label .cm-switch,
.klaro .context-notice .cm-list-label .cm-switch,
.klaro .cookie-notice .cm-list-label .cm-switch {
  position: absolute;
  left: 0;
}
.klaro .cookie-modal .cm-list-label .slider,
.klaro .context-notice .cm-list-label .slider,
.klaro .cookie-notice .cm-list-label .slider {
  background-color: #f2f2f2;
  background-color: var(--white2, #f2f2f2);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 50px;
  display: inline-block;
}
.klaro .cookie-modal .cm-list-label .slider::before,
.klaro .context-notice .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-label .slider::before {
  background-color: #e6e6e6;
  background-color: var(--white3, #e6e6e6);
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.klaro .cookie-modal .cm-list-label .slider.round,
.klaro .context-notice .cm-list-label .slider.round,
.klaro .cookie-notice .cm-list-label .slider.round {
  border-radius: 30px;
}
.klaro .cookie-modal .cm-list-label .slider.round::before,
.klaro .context-notice .cm-list-label .slider.round::before,
.klaro .cookie-notice .cm-list-label .slider.round::before {
  border-radius: 50%;
}
.klaro .cookie-modal .cm-list-label input:focus + .slider,
.klaro .context-notice .cm-list-label input:focus + .slider,
.klaro .cookie-notice .cm-list-label input:focus + .slider {
  box-shadow-color: #48dfb2;
  box-shadow-color: var(--green3, #48dfb2);
  box-shadow: 0 0 1px var(color, green3);
}
.klaro .cookie-modal .cm-list-label input:checked + .slider::before,
.klaro .context-notice .cm-list-label input:checked + .slider::before,
.klaro .cookie-notice .cm-list-label input:checked + .slider::before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
.klaro .cookie-modal .cm-list-input:focus + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:focus + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:focus + .cm-list-label .slider {
  box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2), 5px 5px 10px 0 rgba(125, 125, 125, 0.19);
}
.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider::before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
.klaro .cookie-modal .slider,
.klaro .context-notice .slider,
.klaro .cookie-notice .slider {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
}
.klaro .cookie-modal a,
.klaro .context-notice a,
.klaro .cookie-notice a {
  color: #1a936f;
  color: var(--green1, #1a936f);
  text-decoration: none;
}
.klaro .cookie-modal p,
.klaro .cookie-modal strong,
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal ul,
.klaro .cookie-modal li,
.klaro .context-notice p,
.klaro .context-notice strong,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice ul,
.klaro .context-notice li,
.klaro .cookie-notice p,
.klaro .cookie-notice strong,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice ul,
.klaro .cookie-notice li {
  color: #fafafa;
  color: var(--light1, #fafafa);
}
.klaro .cookie-modal p,
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal ul,
.klaro .cookie-modal li,
.klaro .context-notice p,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice ul,
.klaro .context-notice li,
.klaro .cookie-notice p,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice ul,
.klaro .cookie-notice li {
  display: block;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-top: 0.7em;
}
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal h3,
.klaro .cookie-modal h4,
.klaro .cookie-modal h5,
.klaro .cookie-modal h6,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice h3,
.klaro .context-notice h4,
.klaro .context-notice h5,
.klaro .context-notice h6,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice h3,
.klaro .cookie-notice h4,
.klaro .cookie-notice h5,
.klaro .cookie-notice h6 {
  font-family: inherit;
  font-family: var(--title-font-family, inherit);
}
.klaro .cookie-modal .cm-link,
.klaro .context-notice .cm-link,
.klaro .cookie-notice .cm-link {
  margin-right: 0.5em;
  vertical-align: middle;
}
.klaro .cookie-modal .cm-btn,
.klaro .context-notice .cm-btn,
.klaro .cookie-notice .cm-btn {
  color: #fff;
  color: var(--button-text-color, #fff);
  background-color: #5c5c5c;
  background-color: var(--dark2, #5c5c5c);
  border-radius: 4px;
  border-radius: var(--border-radius, 4px);
  padding: 6px 10px;
  margin-right: 0.5em;
  border-style: none;
  padding: 0.4em;
  font-size: 1em;
  cursor: pointer;
}
.klaro .cookie-modal .cm-btn:disabled,
.klaro .context-notice .cm-btn:disabled,
.klaro .cookie-notice .cm-btn:disabled {
  opacity: 0.5;
}
.klaro .cookie-modal .cm-btn.cm-btn-close,
.klaro .context-notice .cm-btn.cm-btn-close,
.klaro .cookie-notice .cm-btn.cm-btn-close {
  background-color: #c8c8c8;
  background-color: var(--light2, #c8c8c8);
}
.klaro .cookie-modal .cm-btn.cm-btn-success,
.klaro .context-notice .cm-btn.cm-btn-success,
.klaro .cookie-notice .cm-btn.cm-btn-success {
  background-color: #1a936f;
  background-color: var(--green1, #1a936f);
}
.klaro .cookie-modal .cm-btn.cm-btn-success-var,
.klaro .context-notice .cm-btn.cm-btn-success-var,
.klaro .cookie-notice .cm-btn.cm-btn-success-var {
  background-color: #24cc9a;
  background-color: var(--green2, #24cc9a);
}
.klaro .cookie-modal .cm-btn.cm-btn-info,
.klaro .context-notice .cm-btn.cm-btn-info,
.klaro .cookie-notice .cm-btn.cm-btn-info {
  background-color: #2581c4;
  background-color: var(--blue1, #2581c4);
}
.klaro .context-notice {
  border-radius: 4px;
  border-radius: var(--border-radius, 4px);
  border-style: solid;
  border-style: var(--border-style, solid);
  border-width: 1px;
  border-width: var(--border-width, 1px);
  border-color: #c8c8c8;
  border-color: var(--light2, #c8c8c8);
  background-color: #fafafa;
  background-color: var(--light1, #fafafa);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 12px;
  height: 100%;
}
.klaro .context-notice.cm-dark {
  background-color: #333;
  background-color: var(--dark1, #333);
  border-color: #5c5c5c;
  border-color: var(--dark2, #5c5c5c);
}
.klaro .context-notice.cm-dark p {
  color: #fafafa;
  color: var(--light1, #fafafa);
}
.klaro .context-notice.cm-dark p a {
  color: #459cdc;
  color: var(--blue2, #459cdc);
}
.klaro .context-notice p {
  color: #333;
  color: var(--dark1, #333);
  flex-grow: 0;
  text-align: center;
  padding-top: 0;
  margin-top: 0;
}
.klaro .context-notice p a {
  color: #24cc9a;
  color: var(--green2, #24cc9a);
}
.klaro .context-notice p.cm-buttons {
  margin-top: 12px;
}
.klaro .cookie-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 1000;
}
.klaro .cookie-modal.cm-embedded {
  position: relative;
  height: inherit;
  width: inherit;
  left: inherit;
  right: inherit;
  z-index: 0;
}
.klaro .cookie-modal.cm-embedded .cm-modal.cm-klaro {
  position: relative;
  transform: none;
}
.klaro .cookie-modal .cm-bg {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.klaro .cookie-modal .cm-modal.cm-klaro {
  background-color: #333;
  background-color: var(--dark1, #333);
  color: #fafafa;
  color: var(--light1, #fafafa);
  z-index: 1001;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-height: 98%;
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
  overflow: auto;
}
@media (min-width: 660px) {
  .klaro .cookie-modal .cm-modal.cm-klaro {
    border-radius: 4px;
    border-radius: var(--border-radius, 4px);
    position: relative;
    margin: 0 auto;
    max-width: 640px;
    height: auto;
    width: auto;
  }
}
.klaro .cookie-modal .cm-modal .hide {
  border-style: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}
.klaro .cookie-modal .cm-modal .hide svg {
  stroke: #fafafa;
  stroke: var(--light1, #fafafa);
}
.klaro .cookie-modal .cm-modal .cm-footer {
  border-top-color: #5c5c5c;
  border-top-color: var(--dark2, #5c5c5c);
  border-top-width: 1px;
  border-top-width: var(--border-width, 1px);
  border-top-style: solid;
  border-top-style: var(--border-style, solid);
  padding: 1em;
}
.klaro .cookie-modal .cm-modal .cm-footer-buttons {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by {
  font-size: 0.8em;
  padding-top: 4px;
  text-align: right;
  padding-right: 8px;
}
.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by a {
  color: #5c5c5c;
  color: var(--dark2, #5c5c5c);
}
.klaro .cookie-modal .cm-modal .cm-header {
  border-bottom-width: 1px;
  border-bottom-width: var(--border-width, 1px);
  border-bottom-style: solid;
  border-bottom-style: var(--border-style, solid);
  border-bottom-color: #5c5c5c;
  border-bottom-color: var(--dark2, #5c5c5c);
  padding: 1em;
  padding-right: 24px;
}
.klaro .cookie-modal .cm-modal .cm-header h1 {
  margin: 0;
  font-size: 2em;
  display: block;
}
.klaro .cookie-modal .cm-modal .cm-header h1.title {
  padding-right: 20px;
}
.klaro .cookie-modal .cm-modal .cm-body {
  padding: 1em;
}
.klaro .cookie-modal .cm-modal .cm-body ul {
  display: block;
}
.klaro .cookie-modal .cm-modal .cm-body span {
  display: inline-block;
  width: auto;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes {
  padding: 0;
  margin: 0;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-caret,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-caret {
  color: #a0a0a0;
  color: var(--light3, #a0a0a0);
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content {
  margin-left: -40px;
  display: none;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content.expanded,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content.expanded {
  margin-top: 10px;
  display: block;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose {
  position: relative;
  line-height: 20px;
  vertical-align: middle;
  padding-left: 60px;
  min-height: 40px;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose:first-child {
  margin-top: 0;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p {
  margin-top: 0;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p.purposes {
  color: #a0a0a0;
  color: var(--light3, #a0a0a0);
  font-size: 0.8em;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose.cm-toggle-all {
  border-top-width: 1px;
  border-top-width: var(--border-width, 1px);
  border-top-style: solid;
  border-top-style: var(--border-style, solid);
  border-top-color: #5c5c5c;
  border-top-color: var(--dark2, #5c5c5c);
  padding-top: 1em;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-list-title {
  font-weight: 600;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-required {
  color: #5c5c5c;
  color: var(--dark2, #5c5c5c);
  padding-left: 0.2em;
  font-size: 0.8em;
}
.klaro .cookie-notice:not(.cookie-modal-notice) {
  background-color: #333;
  background-color: var(--dark1, #333);
  z-index: 999;
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
}
@media (min-width: 1024px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) {
    border-radius: 4px;
    border-radius: var(--border-radius, 4px);
    position: fixed;
    position: var(--notice-position, fixed);
    right: 20px;
    right: var(--notice-right, 20px);
    left: auto;
    left: var(--notice-left, auto);
    bottom: 20px;
    bottom: var(--notice-bottom, 20px);
    top: auto;
    top: var(--notice-top, auto);
    max-width: 400px;
    max-width: var(--notice-max-width, 400px);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  }
}
@media (max-width: 1023px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) {
    border-style: none;
    border-radius: 0;
  }
}
.klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded {
  position: relative;
  height: inherit;
  width: inherit;
  left: inherit;
  right: inherit;
  bottom: inherit;
  z-index: 0;
}
.klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded .cn-body {
  padding-top: 0.5em;
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
  margin-bottom: 0;
  margin-right: 0;
  bottom: 0;
  padding: 1em;
  padding-top: 0;
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p {
  margin-bottom: 0.5em;
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p.cn-changes {
  text-decoration: underline;
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-learn-more {
  display: inline-block;
  flex-grow: 1;
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
  display: inline-block;
  margin-top: -0.5em;
}
@media (max-width: 384px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
    width: 100%;
  }
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
  margin-top: 0.5em;
}
@media (max-width: 384px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
    width: calc(50% - 0.5em);
  }
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok {
  margin-top: -0.5em;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: right;
  align-items: baseline;
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok a,
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok div {
  margin-top: 0.5em;
}
.klaro .cookie-modal-notice {
  background-color: #333;
  background-color: var(--dark1, #333);
  color: #fafafa;
  color: var(--light1, #fafafa);
  z-index: 1001;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-height: 98%;
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
  overflow: auto;
  padding: 1em;
  padding-top: 0.2em;
}
@media (min-width: 400px) {
  .klaro .cookie-modal-notice {
    border-radius: 4px;
    border-radius: var(--border-radius, 4px);
    position: relative;
    margin: 0 auto;
    max-width: 400px;
    height: auto;
    width: auto;
  }
}
.klaro .cookie-modal-notice .cn-ok {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}
.klaro .cookie-notice-hidden {
  display: none !important;
}

.klaro {
  --dark2: #5c5c5c;
  --dark3: #e6e6e6;
  --white3: #e6e6e6;
  --green1: var(--c-accent);
  --green2: var(--c-accent);
  --border-radius: 0;
}
.klaro .context-notice {
  background: #d9d9d9;
  border: none;
}
.klaro .cookie-modal a,
.klaro .context-notice a,
.klaro .cookie-notice a {
  color: var(--white3);
}
.klaro .cookie-modal .cm-list-label .slider:before,
.klaro .context-notice .cm-list-label .slider:before,
.klaro .cookie-notice .cm-list-label .slider:before {
  background-color: var(--dark2);
}
.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider:before,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider:before,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider:before {
  background-color: var(--white3);
}
.klaro.cm-as-context-notice {
  padding: 0;
}

[data-type=placeholder] {
  max-width: 100% !important;
  height: 100% !important;
}

@media print {
  .accordion__entry {
    page-break-inside: avoid;
  }
}
.accordion__entry-headline {
  color: var(--c-text);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  z-index: 1;
  position: relative;
  width: 100%;
  border: 0;
  border-radius: 0;
  background: transparent;
  text-align: left;
}
.accordion__entry-headline .icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
  transition: all 0.2s ease;
}
html.has-hover .accordion__entry-headline:hover {
  background: var(--c-bg-light);
}
.accordion__entry-headline + div {
  background: linear-gradient(to right, var(--c-bg-light), var(--c-bg-light)) no-repeat;
  background-position: 50% 0;
  background-size: 100% calc(100% - 1rem);
}
.accordion__entry-headline.active {
  background: linear-gradient(to right, var(--c-bg-light), var(--c-bg-light));
}
.accordion__entry-headline.active .icon {
  transform: rotate(180deg);
}
.accordion__entry-headline.active,
.accordion__entry-headline.active + div {
  position: relative;
}
.accordion__entry-headline.active::before,
.accordion__entry-headline.active + div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.125rem;
  height: 100%;
  background: var(--c-accent);
}
.accordion__entry-headline.active + div::before {
  height: calc(100% - 1rem);
}
.accordion__icon-wrapper {
  flex: 0 0 auto;
  margin-left: 1rem;
}
@media print {
  .accordion__icon-wrapper {
    display: none;
  }
}
html.no-js .accordion__icon-wrapper {
  display: none;
}
.accordion__entry-content {
  padding: 1.5rem 1rem 2.5rem;
  position: relative;
  /*
  * Top Border
  */
}
@media (min-width: 768px) {
  .accordion__entry-content {
    padding: 2rem 2rem 3rem;
  }
}
.accordion__entry-content::before {
  content: "";
  position: absolute;
  top: 0;
  height: 1px;
  background: #C8C8C8;
  left: 1rem;
  right: 1rem;
}
@media print {
  .accordion__entry-content {
    padding: 2rem;
  }
}
.accordion .richtext {
  max-width: 47.5rem;
}

.breadcrumb {
  margin-bottom: 1rem;
}
.breadcrumb ul {
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.875rem;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.breadcrumb li {
  display: flex;
}
.breadcrumb li:not(:last-child):after {
  content: "»";
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.6875rem;
  padding: 0.125rem 0.25rem 0;
}
.breadcrumb li a {
  color: rgba(0, 0, 0, 0.65);
}
.breadcrumb li a:hover, .breadcrumb li a:focus {
  text-decoration: underline;
}

.breadcrumb-container.ep-module {
  margin-top: 1rem;
}
.breadcrumb-container.ep-module .breadcrumb {
  margin-bottom: 0;
}

.claim {
  padding-bottom: 3rem;
}
.claim:first-child {
  padding-top: 3rem;
}
.claim .container-fluid, .claim .container-sm, .claim .container-md, .claim .container-lg, .claim .container-xl {
  position: relative;
}
.claim__line {
  display: none;
}
.theme-eppm .claim__line {
  display: block;
  position: absolute;
  left: -3.125rem;
  right: -3.125rem;
  bottom: -1.5rem;
  z-index: 1;
}
.theme-eppm .claim__line svg {
  width: 100%;
  height: auto;
}
.theme-eppm .claim__line svg path {
  transition: stroke-dashoffset 1800ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 1000ms;
  stroke-dashoffset: 1545px;
}
@media (prefers-reduced-motion: reduce) {
  .theme-eppm .claim__line svg path {
    stroke-dashoffset: 0;
  }
}
.theme-eppm .claim__line.js-visible svg path, .no-js .theme-eppm .claim__line svg path {
  stroke-dashoffset: 0;
}

.claim__claim {
  font-weight: 300;
  font-size: 3rem;
  line-height: 1.1;
}
.claim__keyword {
  font-weight: 700;
  transform-origin: center left;
}
.claim__keyword--fade-in {
  animation: 2s claim-keyword-in ease-out;
}
.claim__keyword--fade-out {
  animation: 2s claim-keyword-out ease-in;
}
.claim__subline {
  margin-top: 1.5rem;
}
.claim__text, .claim__image {
  z-index: 1;
}
.claim__image .image {
  transition: 1200ms transform ease-out, 1200ms opacity ease-out;
  opacity: 0;
  transform: translateY(2.5rem);
}
@media (prefers-reduced-motion: reduce) {
  .claim__image .image {
    opacity: 1;
    transform: translateY(0);
  }
}
.claim__image .image.js-visible, .no-js .claim__image .image {
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 768px) {
  .claim {
    padding-bottom: 5rem;
  }
  .claim:first-child {
    padding-top: 5rem;
  }
  .theme-eppm .claim__line {
    bottom: -6.5rem;
  }

  .claim__claim {
    font-size: 3.75rem;
  }
}
@media (min-width: 992px) {
  .claim {
    padding-bottom: 6.5rem;
  }
  .claim:first-child {
    padding-top: 6.5rem;
  }
  .theme-eppm .claim__line {
    bottom: -8rem;
  }

  .claim__claim {
    font-size: 5rem;
  }
}

@keyframes claim-keyword-in {
  0% {
    transform: scale(0.2);
    opacity: 0;
    letter-spacing: 0.25rem;
    filter: blur(1rem);
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
    filter: blur(0.25rem);
  }
  100% {
    transform: scale(1);
    letter-spacing: 0;
    filter: blur(0);
  }
}
@keyframes claim-keyword-out {
  50% {
    opacity: 1;
    transform: scale(1.1);
    filter: blur(4px);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
    letter-spacing: 0.25rem;
    filter: blur(1rem);
  }
}
.divider hr {
  display: block;
  height: 1px;
  background: var(--c-divider-dark);
  border: none;
}

.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
  background: var(--c-footer);
  color: #FFF;
}
.footer .container-fluid, .footer .container-sm, .footer .container-md, .footer .container-lg, .footer .container-xl {
  overflow: hidden;
}
.footer ul {
  list-style: none;
  padding: 0;
}
.footer ul li {
  display: inline-block;
}
.footer a {
  color: #FFF;
  display: inline-flex;
  align-items: center;
}
.footer a .icon {
  fill: currentColor;
  width: 1.25em;
  height: 1.25em;
  margin-left: 0.375em;
}
html.has-hover .footer a:hover {
  text-decoration: underline;
}
.footer__nav {
  font-size: 1rem;
  line-height: 1.25em;
  margin: 0 -0.75rem -1rem;
}
.footer__nav a {
  font-weight: 700;
}
.footer__nav li {
  margin: 0 0.75rem 1rem;
}
.footer__footnote-nav {
  margin: 1.5rem -0.5rem -0.5rem;
}
.footer__footnote-nav li {
  margin: 0 0.5rem 0.5rem;
}
.footer__footnote-nav:first-child {
  margin-top: 0;
}
.footer__social-entry {
  margin: 0 0.5em 0.3125em;
}
.footer__social-entry .icon {
  display: block;
  width: 1.375em;
  height: 1.375em;
  margin-left: 0;
}
.footer__social-entry--linkedin {
  position: relative;
  top: -0.0625em;
}

.form__field-group + .form__field-group {
  margin-top: 2.5rem;
}
@media (min-width: 992px) {
  .form__field-group + .form__field-group {
    margin-top: 4rem;
  }
}
.form__field-group-headline {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .form__field-group-headline {
    margin-bottom: 1.5rem;
  }
}
.form__fields {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.form__fields > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media (min-width: 992px) {
  .form__fields {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .form__fields > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
.form__field {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .form__field {
    margin-bottom: 1.5rem;
  }
}
.form__field > .form-group {
  margin: 0;
}
* + .form__field--headline {
  margin-top: 1.5rem;
}
@media (min-width: 992px) {
  * + .form__field--headline {
    margin-top: 2.5rem;
  }
}
.form__field--headline-small {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .form__field--headline-small {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}
.form__field--radio + .form__field--text-field {
  margin-top: 0.5rem;
}
.form__field--headline + .form__field--subline {
  margin-top: -1rem;
}
* + .form__field--checkbox {
  margin-top: 2.5rem;
}
.form__field:first-child, .form__field:first-child > :first-child {
  margin-top: 0;
}
.form__field:last-child, .form__field:last-child > :last-child {
  margin-bottom: 0 !important;
}
.form__aside .richtext {
  overflow: initial;
}
* + .form__aside {
  margin-top: 5rem;
}
@media (min-width: 992px) {
  * + .form__aside {
    margin-top: 0;
  }
}
.form__last-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
}
.form__last-line p {
  width: 100%;
  flex: 0 0 100%;
}
.form__last-line p:last-child {
  margin-top: 1rem;
}
.form__last-line p:not(:last-child) {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .form__last-line {
    flex-wrap: nowrap;
  }
  .form__last-line p {
    width: auto;
    flex: 1 1 auto;
  }
  .form__last-line p:last-child {
    margin-top: 0;
  }
  .form__last-line p:not(:last-child) {
    margin-bottom: 0;
  }
  .form__last-line p:not(:last-child),
.form__last-line .btn:not(:last-child) {
    margin-right: 1rem;
  }
}
.form--compact .form__field {
  margin-bottom: 1rem;
}
.form--compact .form__last-line {
  margin-top: 1rem;
}

/*
* Bootstrap overrides
*/
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  box-shadow: none;
}

.form-group label,
.form-group .form-text,
.invalid-feedback {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.2307692308em;
}

.form-control {
  background-clip: border-box;
  appearance: none;
  height: 4rem;
  padding: 1.25rem 1rem;
}
html.text-size-large .form-control {
  height: auto;
}
.form-control:focus {
  background-color: #FFF;
  outline: none;
  transition: all 0.2s ease;
}
.form-control html.has-hover::-webkit-search-cancel-button, .form-control.focus-visible::-webkit-search-cancel-button {
  opacity: 1;
  pointer-events: auto;
}
.form-control[disabled] {
  cursor: not-allowed;
}
.form-control[disabled], .form-control[disabled]::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.2) !important;
}
.form-control--small {
  height: 3rem;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

select.form-control {
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='rgb%280%2C0%2C0%29'%3E%3Cpath d='M12 16a.993.993 0 01-.64-.232l-6-5a1 1 0 111.28-1.536l5.371 4.476 5.362-4.315a1 1 0 011.254 1.558l-6 4.828A1 1 0 0112 16'/%3E%3C/svg%3E");
  background-position: right 1rem center;
  background-repeat: no-repeat;
  padding-right: 2.5rem !important;
}

.form-group > label {
  display: block;
  margin: 0;
}
.form-group > label + * {
  margin-top: 0.5rem;
}
.form-group.has-error label {
  color: #D90F30;
}
.form-group.has-error input, .form-group.has-error textarea, .form-group.has-error select {
  border-color: #D90F30;
}
.form-group.has-error .invalid-feedback {
  display: block;
}
.form-group.text-field .invalid-feedback, .form-group.text-area .invalid-feedback, .form-group.select-field .invalid-feedback {
  color: #D90F30;
}
.form-group.text-field.has-label .form-control, .form-group.select-field.has-label .form-control {
  padding: 2rem 1rem 0.5rem;
}
.form-group.text-field.has-label label, .form-group.select-field.has-label label {
  position: relative;
}
.form-group.text-field.has-label label span, .form-group.select-field.has-label label span {
  position: absolute;
  top: 1.3125rem;
  left: 0.875rem;
  transform: translateY(0) scale(1);
  transform-origin: 0 50%;
  transition: all 0.2s ease;
  color: #757575;
  pointer-events: none;
}
.form-group.text-field.has-label label .has-value + span, .form-group.select-field.has-label label .has-value + span {
  transform: translateX(0.1875rem) translateY(-0.875rem) scale(0.8125);
}
.form-group.text-field textarea, .form-group.select-field textarea {
  height: 10rem;
}
.form-group.text-field.has-label label :focus + span {
  transform: translateX(0.1875rem) translateY(-0.875rem) scale(0.8125);
}
.form-group.text-area .form-control {
  padding: 0.875rem 1rem;
  height: 10rem;
}
.form-group.text-area label {
  position: relative;
}
.form-group.text-area label span {
  position: absolute;
  top: 1.125rem;
  left: 1rem;
  transform: translateY(0) scale(1);
  transform-origin: 0 50%;
  color: #757575;
  pointer-events: none;
}
.form-group.text-area label :focus + span,
.form-group.text-area label .has-value + span {
  display: none;
}

.checkbox.has-error .checkbox__label {
  color: #D90F30;
}
.checkbox.has-error .invalid-feedback {
  display: block;
  margin-top: 0;
}
.checkbox.has-error label input + div::before {
  border: 1px solid #D90F30;
}
.checkbox__label {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.2307692308em;
  margin-bottom: 0.5rem;
}
.checkbox label {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 2.5rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.375em;
  margin: 0;
}
.checkbox label input {
  opacity: 0;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0.5rem;
  left: 0;
  pointer-events: none;
}
.checkbox label div {
  padding: 0.5em 2.5em;
}
.checkbox label div::before {
  content: "";
  width: 1.5em;
  height: 1.5em;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  background: #F8F8F8;
  position: absolute;
  top: 0.5em;
  left: 0;
}
.checkbox label input.focus-visible + div::before, html.has-hover .checkbox label:hover input + div::before {
  border-color: var(--c-accent);
  background-color: #FFF;
}
.checkbox label :checked + div::before,
.checkbox label input.focus-visible:checked + div::before, html.has-hover .checkbox label:hover input:checked + div::before {
  background: #000;
}
.checkbox label :checked + div::after,
.checkbox label input.focus-visible:checked + div::after, html.has-hover .checkbox label:hover input:checked + div::after {
  content: "";
  width: 1.5em;
  height: 1.5em;
  background: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24' fill='rgb%28255%2C255%2C255%29'%3E%3Cdefs%3E%3Cpath id='checkmark-a' d='M9.863 18a1 1 0 01-.729-.315l-4.863-5.179a1 1 0 111.457-1.369l4.125 4.391 8.408-9.202a1 1 0 011.477 1.348l-9.137 10a.998.998 0 01-.73.326h-.008z'/%3E%3C/defs%3E%3Cuse xlink:href='%23checkmark-a'/%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0.5em;
  left: 0;
  pointer-events: none;
}
.checkbox label input:checked.focus-visible + div::before {
  outline: 2px dashed #000;
}
.checkbox label .richtext {
  max-width: 100%;
}
html.text-size-large .checkbox label .richtext {
  hyphens: auto;
}

.radio.has-error .radio__label {
  color: #D90F30;
}
.radio.has-error .invalid-feedback {
  display: block;
}
.radio__label {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.2307692308em;
  margin-bottom: 0.5rem;
  display: inline-block;
}
.radio__options-wrapper {
  margin-bottom: 1px;
}
.radio__options-wrapper > * {
  float: left;
  margin-bottom: -1px !important;
}
.radio__options-wrapper > * > div::before {
  border: 1px solid #999;
}
.radio__options-wrapper > *.focus-within, html.has-hover .radio__options-wrapper > *:hover {
  position: relative;
  z-index: 1;
}
.radio__options-wrapper > *.focus-within > div::before, html.has-hover .radio__options-wrapper > *:hover > div::before {
  border-color: var(--c-accent);
}
.radio--single-line .radio__options-wrapper > * {
  margin-right: -1px;
}
.radio--single-line .radio__options-wrapper > *:first-child > div::before {
  border-radius: 2px 0 0 2px;
}
.radio--single-line .radio__options-wrapper > *:last-child > div::before {
  border-radius: 0 2px 2px 0;
}
.radio:not(.radio--single-line) .radio__options-wrapper > * {
  margin-right: -1px;
  width: 50%;
}
.radio:not(.radio--single-line) .radio__options-wrapper > * > div::before {
  border-radius: 2px;
}
.radio__options-wrapper::after {
  content: "";
  clear: left;
  display: table;
  width: 100%;
}
.radio__input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 2.5rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.375em;
  margin: 0;
}
.radio__input-wrapper:hover, .radio__input-wrapper:focus, .radio__input-wrapper {
  color: #000;
}
.radio__input-wrapper input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.radio__input-wrapper > div {
  padding: 1.25em 1.5em 1.25em 3.5em;
  font-size: 1rem;
  line-height: 1.375em;
}
.radio__input-wrapper > div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}
.radio__input-wrapper > div::after {
  content: "";
  width: 1.5em;
  height: 1.5em;
  background: #F8F8F8;
  border: 1px solid rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 1.25em;
  left: 1em;
  border-radius: 50%;
  z-index: 1;
}
.radio__input-wrapper > div div {
  position: relative;
  z-index: 1;
}
.radio__input-wrapper input.focus-visible + div::after, html.has-hover .radio__input-wrapper:hover > div::after {
  border-color: var(--c-accent);
  background-color: #FFF;
}
.radio__input-wrapper input.focus-visible + div::before, html.has-hover .radio__input-wrapper:hover > div::before {
  background: var(--c-accent-10);
}
.radio__input-wrapper :checked + div::after, .radio__input-wrapper.checked > div::after {
  border: 0.5em solid #000 !important;
}
.radio__input-wrapper :checked + div::before, .radio__input-wrapper.checked > div::before {
  background: var(--c-accent-10);
  border-color: var(--c-accent);
}
.radio a.radio__input-wrapper:focus {
  outline: none;
}
.radio a.radio__input-wrapper.focus-visible div::after, .radio a.radio__input-wrapper.focus-visible span::after {
  border: 0.125em solid #000;
}
.radio.has-error label {
  color: var(--c-text);
}
.radio.has-error label > div::before {
  border-color: #D90F30;
}

.select-field .form-group__icon-wrapper {
  position: relative;
}
.select-field .form-group__icon-wrapper .icon,
.select-field .form-group__icon-wrapper button {
  position: absolute;
  top: 50%;
  left: 0.625rem;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-50%);
  pointer-events: none;
}
.select-field .form-group__icon-wrapper button {
  pointer-events: all;
  border: 0;
  padding: 0;
  border-radius: 0;
  background: transparent;
}
.select-field .form-group__icon-wrapper button .icon {
  position: static;
  width: 100%;
  height: 100%;
  transform: none;
  display: block;
}
.select-field .form-group__icon-wrapper button.focus-visible {
  outline: 2px solid #000;
}
.select-field .form-group__icon-wrapper select {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-field .form-group__icon-wrapper:not(.form-group__icon-wrapper--icon-right) select {
  padding-left: 2.625rem;
}
.select-field .form-group__icon-wrapper--icon-right select {
  padding-right: 2.625rem;
}
.select-field .form-group__icon-wrapper--icon-right .icon,
.select-field .form-group__icon-wrapper--icon-right button {
  left: auto;
  right: 0.625rem;
}

.text-field .form-group__icon-wrapper {
  position: relative;
}
.text-field .form-group__icon-wrapper .icon,
.text-field .form-group__icon-wrapper button {
  position: absolute;
  top: 50%;
  left: 0.625rem;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-50%);
  pointer-events: none;
}
.text-field .form-group__icon-wrapper button {
  pointer-events: all;
  border: 0;
  padding: 0;
  border-radius: 0;
  background: transparent;
}
.text-field .form-group__icon-wrapper button .icon {
  position: static;
  width: 100%;
  height: 100%;
  transform: none;
  display: block;
}
.text-field .form-group__icon-wrapper button.focus-visible {
  outline: 2px solid #000;
}
.text-field .form-group__icon-wrapper input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-field .form-group__icon-wrapper:not(.form-group__icon-wrapper--icon-right) input {
  padding-left: 2.625rem;
}
.text-field .form-group__icon-wrapper--icon-right input {
  padding-right: 2.625rem;
}
.text-field .form-group__icon-wrapper--icon-right .icon,
.text-field .form-group__icon-wrapper--icon-right button {
  left: auto;
  right: 0.625rem;
}

.toggle-group:not(.toggled) .checkbox + * {
  display: none;
}

.image {
  text-align: center;
}
.image--full-width > * {
  max-width: none;
  padding: 0;
}
.image--full-width > * .image > * {
  width: 100% !important;
}

.image-teaser {
  /*
  *   Text Styling
  */
}
.image-teaser__intro {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .image-teaser__intro {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 992px) {
  .image-teaser__intro {
    margin-bottom: 3rem;
  }
}
@media (min-width: 992px) {
  .image-teaser__headline {
    text-align: center;
  }
}
.image-teaser__entries {
  justify-content: center;
  margin-bottom: -2rem;
}
@media (min-width: 768px) {
  .image-teaser__entries {
    margin-bottom: -2.5rem;
  }
}
@media (min-width: 992px) {
  .image-teaser__entries {
    margin-bottom: -3rem;
  }
}
.image-teaser__entries > * {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .image-teaser__entries > * {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 992px) {
  .image-teaser__entries > * {
    margin-bottom: 3rem;
  }
}
.image-teaser__entry-text {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .image-teaser__entry-text {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .image-teaser__entry-text {
    margin-top: 1.5rem;
  }
}
.image-teaser__entry > a {
  display: block;
  color: var(--c-text);
  text-decoration: none;
  height: 100%;
}
html.has-hover .image-teaser__entry > a:hover, .image-teaser__entry > a:focus {
  text-decoration: none;
}
.image-teaser__entry > a > div,
.image-teaser__entry > a .image {
  transition: all 0.2s ease;
}
.image-teaser__entry .richtext:not(:first-child) {
  margin-top: 1rem;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .image-teaser__entry .row {
    align-items: center;
  }
}
.image-teaser__entry-headline {
  display: inline-flex;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25em;
  transition: all 0.2s ease;
}
.image-teaser__entry-headline .icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5em;
  transition: all 0.2s ease;
  transform: scale(1);
  fill: currentColor;
  align-self: center;
  flex: 0 0 auto;
}
.image-teaser__entry-headline .icon:last-child {
  margin-left: 0.5em;
  margin-right: 0;
}
.image-teaser__entry-headline span {
  padding: 5px 0 3px;
  display: inline-block;
  background-image: linear-gradient(to right, var(--c-link), var(--c-link));
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: 0 100%;
  transition: all 0.2s ease;
}
.image-teaser__entry-headline--external .icon {
  order: 1;
  margin-left: 0.5em;
  margin-right: 0;
}
.image-teaser__entry-footer:not(:first-child) {
  margin-top: 1rem;
}
.image-teaser__entry-link {
  display: inline-flex;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25em;
  transition: all 0.2s ease;
}
.image-teaser__entry-link .icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5em;
  transition: all 0.2s ease;
  transform: scale(1);
  fill: currentColor;
  align-self: center;
  flex: 0 0 auto;
}
.image-teaser__entry-link .icon:last-child {
  margin-left: 0.5em;
  margin-right: 0;
}
.image-teaser__entry-link span {
  padding: 5px 0 3px;
  display: inline-block;
  background-image: linear-gradient(to right, var(--c-link), var(--c-link));
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: 0 100%;
  transition: all 0.2s ease;
}
.image-teaser__entry-link--external .icon {
  order: 1;
  margin-left: 0.5em;
  margin-right: 0;
}
html.has-hover .image-teaser__entry > a:hover > div,
html.has-hover .image-teaser__entry > a:hover .image, .image-teaser__entry > a:focus > div,
.image-teaser__entry > a:focus .image {
  transform: translateY(-4px);
}
html.has-hover .image-teaser__entry > a:hover .image-teaser__entry-headline, .image-teaser__entry > a:focus .image-teaser__entry-headline {
  transform: translateY(-2px);
}
html.has-hover .image-teaser__entry > a:hover .image-teaser__entry-headline span, .image-teaser__entry > a:focus .image-teaser__entry-headline span {
  background-size: 100% 3px;
}
html.has-hover .image-teaser__entry > a:hover .image-teaser__entry-link, .image-teaser__entry > a:focus .image-teaser__entry-link {
  transform: translateY(-2px);
}
html.has-hover .image-teaser__entry > a:hover .image-teaser__entry-link span, .image-teaser__entry > a:focus .image-teaser__entry-link span {
  background-size: 100% 3px;
}

.line-teaser__line {
  display: none;
}
.theme-eppm .line-teaser__line {
  display: block;
  position: absolute;
  left: -10%;
  bottom: -1.625rem;
  width: 120%;
  z-index: 1;
}
.theme-eppm .line-teaser__line svg {
  width: 100%;
  height: auto;
}
.theme-eppm .line-teaser__line svg path {
  transition: stroke-dashoffset 1800ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 1000ms;
  stroke-dashoffset: 1357px;
}
@media (prefers-reduced-motion: reduce) {
  .theme-eppm .line-teaser__line svg path {
    stroke-dashoffset: 0;
  }
}
.theme-eppm .line-teaser__line.js-visible svg path, .no-js .theme-eppm .line-teaser__line svg path {
  stroke-dashoffset: 0;
}

.line-teaser__teasers {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  position: relative;
}
.line-teaser__teasers.js-visible .line-teaser__teaser, .no-js .line-teaser__teasers .line-teaser__teaser {
  opacity: 1;
  transform: translateY(0);
}
.line-teaser__teaser {
  position: relative;
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 50%;
  max-width: 50%;
  transition: 1200ms transform ease-out, 1200ms opacity ease-out;
  opacity: 0;
  transform: translateY(2.5rem);
}
.line-teaser__teaser--first {
  margin-top: 1.5rem;
  z-index: 2;
}
.line-teaser__teaser--second {
  transition-delay: 600ms;
}
.line-teaser__teaser-inner {
  position: relative;
}
.line-teaser__teaser-inner .line-teaser__eppg-lines {
  display: none;
}
.theme-eppg .line-teaser__teaser-inner .line-teaser__eppg-lines {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}
.theme-eppg .line-teaser__teaser-inner .line-teaser__eppg-lines svg {
  color: var(--c-accent);
  max-width: 100%;
  height: auto;
}

.theme-eppg .line-teaser__teaser--first .line-teaser__teaser-inner .line-teaser__eppg-lines {
  width: 175%;
  transform: translate(1.125rem, 1.8125rem);
}
@media (min-width: 576px) {
  .theme-eppg .line-teaser__teaser--first .line-teaser__teaser-inner .line-teaser__eppg-lines {
    width: 100%;
    transform: translate(-2.375rem, 1.9375rem);
  }
}
@media (min-width: 992px) {
  .theme-eppg .line-teaser__teaser--first .line-teaser__teaser-inner .line-teaser__eppg-lines {
    transform: translate(-3.5625rem, 3rem);
  }
}

.theme-eppg .line-teaser__teaser--second {
  z-index: 10;
}

.theme-eppg .line-teaser__teaser--second .line-teaser__teaser-inner .line-teaser__eppg-lines {
  display: none;
}
@media (min-width: 576px) {
  .theme-eppg .line-teaser__teaser--second .line-teaser__teaser-inner .line-teaser__eppg-lines {
    display: block;
    transform: translate(2.1875rem, -2.0625rem);
  }
}
@media (min-width: 992px) {
  .theme-eppg .line-teaser__teaser--second .line-teaser__teaser-inner .line-teaser__eppg-lines {
    transform: translate(3.5rem, -3rem);
  }
}

.line-teaser__teaser-text {
  transition: all 0.2s ease;
}
.line-teaser__teaser-text .display-l {
  margin-bottom: 1rem;
}
.line-teaser__teaser-text .display-l:last-child {
  margin-bottom: 0;
}
.line-teaser__teaser-text--mobile {
  margin-top: 3rem;
}
.line-teaser__teaser-text--desktop {
  --c-headline: #FFF;
  --c-text: #FFF;
  --c-link: #FFF;
  display: none;
  padding: 1.5rem;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
@media (min-width: 768px) {
  .theme-eppm .line-teaser__line {
    left: 0;
    bottom: -2.5rem;
    width: 100%;
  }

  .line-teaser__teasers {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .theme-eppm .line-teaser__teasers:before {
    bottom: -2.875rem;
    left: 0;
    width: 100%;
    padding-top: 26.2%;
  }

  .line-teaser__teaser {
    position: relative;
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .line-teaser__teaser--first {
    margin-left: 8.3333333333%;
    margin-top: 3rem;
  }
  .line-teaser__teaser .image {
    transition: all 0.2s ease;
  }
  .line-teaser__teaser-text--mobile {
    display: none;
  }
  .line-teaser__teaser-text--desktop {
    display: flex;
  }
  .line-teaser__teaser-text .txt-btn {
    font-size: 1rem;
  }
  .line-teaser__teaser[data-href]:hover .image {
    transform: translateY(-0.5rem);
  }
  .line-teaser__teaser[data-href]:hover .line-teaser__teaser-text {
    transform: translateY(-0.75rem);
  }
  .line-teaser__teaser[data-href]:hover .line-teaser__teaser-text .txt-btn {
    transform: translateY(-2px);
  }
  .line-teaser__teaser[data-href]:hover .line-teaser__teaser-text .txt-btn span {
    background-size: 100% 3px;
  }
}
@media (min-width: 992px) {
  .theme-eppm .line-teaser__line {
    left: 10%;
    bottom: -4rem;
    width: 80%;
  }

  .theme-eppm .line-teaser__teasers:before {
    bottom: -4.375rem;
    left: 10%;
    width: 80%;
    padding-top: 20.96%;
  }

  .line-teaser__teaser {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .line-teaser__teaser--first {
    margin-left: 16.6666666667%;
    margin-top: 4rem;
  }
}

.js-autosuggest {
  position: relative;
}
.js-autosuggest__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  background: #FFF;
  z-index: 1;
  box-shadow: 0 12px 18px -9px rgba(0, 20, 63, 0.15);
  overflow: hidden;
}
.js-autosuggest__entry {
  padding: 0.3em 1rem;
  cursor: pointer;
}
.js-autosuggest__entry:hover, .js-autosuggest__entry.active {
  color: var(--c-accent);
}
.js-autosuggest__entry:first-child {
  margin-top: 0.5rem;
}
.js-autosuggest__entry:last-child {
  margin-bottom: 0.5rem;
}
.js-autosuggest .text-m {
  display: inline-block;
}
.js-autosuggest[data-make-icon-submit] .form-group .icon {
  pointer-events: all;
  cursor: pointer;
}
html.no-js .js-autosuggest__suggestions {
  display: none;
}

.js-collapse-wrapper {
  overflow: hidden;
  transition: 0.2s max-height ease-in-out;
}
@media screen {
  html.js .js-collapse-wrapper {
    max-height: 0;
  }
  html.js .active + .js-collapse-wrapper {
    max-height: none;
  }
}

.js-long-link-wrap {
  word-break: break-all;
}

.navigation {
  background-color: #FFF;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 20;
  /*
  *   Mobile
  */
}
.navigation > * {
  z-index: 2;
  position: relative;
}
.navigation ul {
  margin: 0;
  padding: 0;
}
.navigation button {
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
}
.navigation a:hover {
  text-decoration: none;
}
.navigation__content {
  display: flex;
  align-items: center;
  background: #FFF;
  height: 4rem;
}
@media (min-width: 992px) {
  .navigation__content {
    height: 6rem;
  }
}
.navigation__logo {
  height: 2.5rem;
  line-height: 2.5rem;
  flex: 0 0 auto;
}
@media (min-width: 992px) {
  .navigation__logo {
    height: 3.5rem;
    line-height: 3.5rem;
  }
}
.navigation__logo img {
  display: block;
  width: auto;
  height: 100%;
  max-width: none;
}
.navigation__logo a {
  color: #000;
}
.navigation__desktop-nav {
  display: none;
}
@media (min-width: 992px) {
  .navigation__desktop-nav {
    display: block;
    flex: 1 0 auto;
  }
}
.navigation__main-nav ul, .navigation__mobile-menu ul, .navigation__mobile-language-selector ul {
  list-style-type: none;
}
.navigation__main-nav a, .navigation__mobile-menu a, .navigation__mobile-language-selector a {
  display: inline-block;
  font-size: 1rem;
  line-height: 3em;
  min-width: 100%;
  color: #000;
}
.navigation__main-nav a.active, .navigation__mobile-menu a.active, .navigation__mobile-language-selector a.active {
  font-weight: 700;
}
.navigation__main-nav a .icon, .navigation__mobile-menu a .icon, .navigation__mobile-language-selector a .icon {
  width: 1em;
  height: 1em;
  position: relative;
  top: -0.1em;
}
@media (min-width: 992px) {
  .navigation__main-nav a .icon, .navigation__mobile-menu a .icon, .navigation__mobile-language-selector a .icon {
    width: 1.25em;
    height: 1.25em;
  }
}
.navigation__main-nav {
  display: flex;
  justify-content: flex-end;
  font-size: 0;
}
.navigation__main-nav li > a.active, .navigation__main-nav li > a.touch-click, .navigation__main-nav li > a:hover, .navigation__main-nav li > a:focus {
  position: relative;
}
.navigation__main-nav li > a.active::after, .navigation__main-nav li > a.touch-click::after, .navigation__main-nav li > a:hover::after, .navigation__main-nav li > a:focus::after {
  content: "";
  background: var(--c-accent);
  position: absolute;
  top: 0;
  width: calc(100% - 2rem);
  left: 1rem;
  height: 0.125rem;
}
.navigation__main-nav__language-selector {
  margin-left: 0.5rem;
}
@media (min-width: 1200px) {
  .navigation__main-nav__language-selector {
    margin-left: 1.5rem;
  }
}
.navigation__main-nav__language-selector > a {
  font-weight: 700;
}
.navigation__main-nav__language-selector > a .icon {
  width: 1rem;
  height: 1rem;
}
.navigation__main-nav > ul > li:hover > a {
  position: relative;
}
.navigation__main-nav > ul > li:hover > a::after {
  content: "";
  background: var(--c-accent);
  position: absolute;
  top: 0;
  width: calc(100% - 2rem);
  left: 1rem;
  height: 0.125rem;
}
.navigation__main-nav > ul {
  list-style: none;
}
.navigation__main-nav > ul > li {
  display: inline-block;
}
.navigation__main-nav > ul > li > a {
  line-height: 6em;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
@media (min-width: 1200px) {
  .navigation__main-nav > ul > li > a {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.navigation__main-nav > ul > li {
  height: 6rem;
  display: inline-flex;
  align-items: center;
}
.navigation__main-nav > ul > li > ul,
.navigation__main-nav > ul > li > .navigation__overlay {
  background: #FFF;
  position: absolute;
  top: 100%;
}
.navigation__main-nav > ul > li > ul li > a,
.navigation__main-nav > ul > li > .navigation__overlay li > a {
  padding: 0.75em 1.5rem;
  line-height: inherit;
}
.navigation__main-nav > ul > li > ul li > a.active::after, .navigation__main-nav > ul > li > ul li > a:hover::after, .navigation__main-nav > ul > li > ul li > a:focus::after,
.navigation__main-nav > ul > li > .navigation__overlay li > a.active::after,
.navigation__main-nav > ul > li > .navigation__overlay li > a:hover::after,
.navigation__main-nav > ul > li > .navigation__overlay li > a:focus::after {
  display: block;
  top: 0;
  width: 0.125rem;
  left: 0;
  height: 100%;
}
.navigation__main-nav > ul > li > ul li > a:hover, .navigation__main-nav > ul > li > ul li > a:focus,
.navigation__main-nav > ul > li > .navigation__overlay li > a:hover,
.navigation__main-nav > ul > li > .navigation__overlay li > a:focus {
  background: var(--c-bg-light);
  padding-left: 2rem;
}
.navigation__main-nav > ul > li > .navigation__overlay {
  left: 0;
  width: 100%;
  display: flex;
  padding: 0.5rem 0.5rem 2rem;
}
.navigation__main-nav > ul > li > .navigation__overlay > div {
  background: var(--c-bg-light);
  margin: 0 2.5%;
  padding: 2rem;
  width: 35%;
  order: -1;
}
@media (min-width: 1200px) {
  .navigation__main-nav > ul > li > .navigation__overlay > div {
    padding: 4rem;
    width: 45%;
  }
}
.navigation__main-nav > ul > li > .navigation__overlay > div .display-m:not(:last-child) {
  margin-bottom: 1.5rem;
}
.navigation__main-nav > ul > li > .navigation__overlay > div .richtext:not(:last-child) {
  margin-bottom: 1.3125rem;
}
.navigation__main-nav > ul > li > .navigation__overlay > div .txt-btn {
  line-height: 1.5;
  min-width: 0;
}
.navigation__main-nav > ul > li > .navigation__overlay > ul {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
  display: flex !important;
  width: 60%;
}
@media (min-width: 1200px) {
  .navigation__main-nav > ul > li > .navigation__overlay > ul {
    width: 50%;
  }
}
.navigation__main-nav > ul > li > .navigation__overlay > ul > li {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  flex: 0 0 50%;
  max-width: 50%;
}
.navigation__main-nav > ul > li > .navigation__overlay > ul > li > a {
  font-weight: bold;
}
.navigation__main-nav > ul > li > .navigation__overlay > ul > li > a.active, .navigation__main-nav > ul > li > .navigation__overlay > ul > li > a:hover, .navigation__main-nav > ul > li > .navigation__overlay > ul > li > a:focus {
  background: inherit;
}
.navigation__main-nav > ul > li > .navigation__overlay > ul > li > ul {
  margin-left: 1.5rem;
}
.navigation__main-nav > ul > li > .navigation__overlay > ul > li > ul li > a {
  position: relative;
}
.navigation__main-nav > ul > li > .navigation__overlay > ul > li > ul li > a::after {
  content: "";
  background: var(--c-divider-dark);
  position: absolute;
  top: 0;
  width: 0.0625rem;
  left: 0;
  height: 100%;
}
.navigation__main-nav > ul > li > .navigation__overlay > ul > li > ul li > a.active:after, .navigation__main-nav > ul > li > .navigation__overlay > ul > li > ul li > a:hover:after, .navigation__main-nav > ul > li > .navigation__overlay > ul > li > ul li > a:focus:after {
  background-color: var(--c-accent);
}
.navigation__main-nav > ul > li > ul {
  width: 250px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navigation__main-nav > ul > li > ul > li > ul {
  margin-left: 1.5rem;
}
.navigation__main-nav > ul > li > ul > li > ul li > a {
  position: relative;
}
.navigation__main-nav > ul > li > ul > li > ul li > a::after {
  content: "";
  background: var(--c-divider-dark);
  position: absolute;
  top: 0;
  width: 0.0625rem;
  left: 0;
  height: 100%;
}
.navigation__main-nav > ul > li:nth-last-child(2) > ul,
.navigation__main-nav > ul > li:last-child > ul {
  right: 2rem;
}
ul .navigation__main-nav__language-selector ul {
  width: 100px !important;
}
.navigation__main-nav > ul > li > ul,
.navigation__main-nav > ul > li > .navigation__overlay {
  display: none;
}
.navigation__main-nav > ul > li > a:hover + ul, .navigation__main-nav > ul > li > a:focus + ul, .navigation__main-nav > ul > li > button:hover + ul, .navigation__main-nav > ul > li > button:focus + ul {
  display: block;
}
.navigation__main-nav > ul > li > a:hover + .navigation__overlay, .navigation__main-nav > ul > li > a:focus + .navigation__overlay, .navigation__main-nav > ul > li > button:hover + .navigation__overlay, .navigation__main-nav > ul > li > button:focus + .navigation__overlay {
  display: flex;
}
.navigation__main-nav > ul > li:hover ul, .navigation__main-nav > ul > li:focus-within ul {
  display: block;
}
.navigation__main-nav > ul > li:hover .navigation__overlay, .navigation__main-nav > ul > li:focus-within .navigation__overlay {
  display: flex;
}
.navigation__main-nav [data-tablet-touch-visible] {
  display: none;
}
.navigation__main-nav .js-touched + ul,
.navigation__main-nav .js-touched + ul [data-tablet-touch-visible] {
  display: block;
}
.navigation__main-nav .js-touched + .navigation__overlay {
  display: flex;
}
.navigation__language-selector {
  margin-left: 3rem;
}
.navigation__mobile-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
@media (min-width: 992px) {
  .navigation__mobile-nav {
    display: none;
  }
}
.navigation__mobile-nav button {
  margin: 0;
}
.navigation__mobile-nav .navigation__search {
  margin: 0;
}
.navigation__menu-btn {
  width: 3rem;
  height: 3.0625rem;
}
.navigation__menu-btn button {
  display: inline-block;
  position: relative;
  width: 3rem;
  height: 3.0625rem;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
}
.navigation__menu-btn button.focus-visible {
  border: 2px solid #000;
}
.navigation__menu-btn button > span:first-child {
  position: absolute;
  left: -9999px;
}
@media (min-width: 992px) {
  .navigation__menu-btn {
    display: none;
  }
}
.navigation__menu-open-icon {
  display: inline-block;
  width: 3rem;
  height: 3.0625rem;
  position: relative;
  transform-origin: 50% 50%;
}
.navigation__menu-open-icon .bun-top,
.navigation__menu-open-icon .patty-1,
.navigation__menu-open-icon .patty-2,
.navigation__menu-open-icon .bun-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 1.5rem;
  height: 0.1875rem;
  margin-left: 12px;
  background: #032A3F;
  transition: all 0.2s ease;
  transform-origin: 50% 50%;
}
.navigation__menu-open-icon .bun-top {
  transform: translate(0px, 15px);
}
.navigation__menu-open-icon .bun-bottom {
  transform: translate(0px, 31px);
}
.navigation__menu-open-icon .patty-1,
.navigation__menu-open-icon .patty-2 {
  transform: translate(0px, 23px);
}
.navigation__menu-btn button.open .navigation__menu-open-icon .bun-top,
.navigation__menu-btn button.open .navigation__menu-open-icon .bun-bottom {
  transform: translate(0px, 23px);
  opacity: 0;
}
.navigation__menu-btn button.open .navigation__menu-open-icon .patty-1 {
  transform: translate(0px, 23px) rotate(45deg);
}
.navigation__menu-btn button.open .navigation__menu-open-icon .patty-2 {
  transform: translate(0px, 23px) rotate(-45deg);
}
.navigation__mobile-menu {
  position: absolute;
  top: 100%;
  background: #FFF;
  height: calc(100vh - 4rem);
  height: calc(var(--app-height) - 4rem);
  overflow: auto;
  transform: translateY(-100%);
  opacity: 0;
  transition: 0.2s transform ease-in-out, 0.3s opacity ease-in-out;
  z-index: 0;
  padding-top: 0.5rem;
  padding-bottom: 5rem;
}
@media (min-width: 768px) {
  .navigation__mobile-menu {
    height: auto;
    max-height: calc(100vh - 4rem);
    max-height: calc(var(--app-height) - 4rem);
  }
}
.navigation__mobile-menu-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
.navigation__mobile-menu-content > * {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.navigation__mobile-menu .navigation__mobile-main-nav li {
  position: relative;
}
.navigation__mobile-menu .navigation__mobile-main-nav li li::after {
  content: "";
  background: #C8C8C8;
  display: block;
  position: absolute;
  top: 0;
  width: 0.0625rem;
  left: 0;
  height: 100%;
}
.navigation__mobile-menu .navigation__mobile-main-nav a {
  display: inline-block;
  position: relative;
  line-height: inherit;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.navigation__mobile-menu .navigation__mobile-main-nav a.active::after, .navigation__mobile-menu .navigation__mobile-main-nav a:hover::after, .navigation__mobile-menu .navigation__mobile-main-nav a:focus::after {
  content: "";
  background: var(--c-accent);
  position: absolute;
  top: 0;
  width: 0.125rem;
  left: 0;
  height: 100%;
  z-index: 1;
}
.navigation__mobile-menu .navigation__mobile-main-nav a:hover, .navigation__mobile-menu .navigation__mobile-main-nav a:focus {
  padding-left: 2rem;
  background: var(--c-bg-light);
}
.navigation__mobile-menu .navigation__mobile-main-nav a:hover::after, .navigation__mobile-menu .navigation__mobile-main-nav a:focus::after {
  display: block;
  top: 0;
  width: 0.125rem;
  left: 0;
  height: 100%;
}
.navigation__mobile-menu .navigation__mobile-main-nav ul {
  margin-left: 1.5rem;
}
.navigation__mobile-menu .navigation__mobile-main-nav ul a::before {
  content: "";
  background: #C8C8C8;
  position: absolute;
  top: 0;
  width: 0.0625rem;
  left: 0;
  height: 100%;
}
.navigation__mobile-menu.open {
  transform: translateY(0);
  opacity: 1;
}
@media (min-width: 768px) {
  .navigation__mobile-menu {
    width: 20rem;
    max-width: 100vw;
    right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  .navigation__mobile-menu {
    display: none;
  }
}
.navigation__mobile-language-selector {
  display: flex;
}
.navigation__mobile-language-selector.navigation__mobile-language-selector {
  margin-top: 1.5rem;
}
.navigation__mobile-language-selector a {
  width: 4.4375rem;
  line-height: 3rem;
  text-align: center;
}
.navigation__mobile-language-selector a.active {
  font-weight: 700;
}
.navigation__mobile-language-selector a:hover, .navigation__mobile-language-selector a.focus-visible {
  background: var(--c-bg-light);
}

.pagination {
  margin-top: 2.5rem;
}
@media (min-width: 768px) {
  .pagination {
    margin-top: 5rem;
  }
}
@media (min-width: 992px) {
  .pagination {
    margin-top: 6.5rem;
  }
}
.pagination__links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.pagination__link a {
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  color: var(--c-text);
  border: 0.0625rem solid var(--c-divider-dark);
  border-radius: 50%;
}
@media (prefers-reduced-motion: reduce) {
  .pagination__link a {
    transition: none;
  }
}
.pagination__link a:hover, .pagination__link a:focus {
  color: #FFF;
  background: var(--c-link);
  border-color: var(--c-link);
  transform: scale(1.15);
}
.pagination__link--current a {
  color: #FFF;
  background: var(--c-link);
  border-color: var(--c-link);
}
.pagination__link--prev a, .pagination__link--next a {
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='rgb%280%2C0%2C0%29'%3E%3Cpath d='M12 16a.993.993 0 01-.64-.232l-6-5a1 1 0 111.28-1.536l5.371 4.476 5.362-4.315a1 1 0 011.254 1.558l-6 4.828A1 1 0 0112 16'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
}
.pagination__link--prev a:hover, .pagination__link--prev a:focus, .pagination__link--next a:hover, .pagination__link--next a:focus {
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='rgb%28255%2C255%2C255%29'%3E%3Cpath d='M12 16a.993.993 0 01-.64-.232l-6-5a1 1 0 111.28-1.536l5.371 4.476 5.362-4.315a1 1 0 011.254 1.558l-6 4.828A1 1 0 0112 16'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
}
.pagination__link--prev {
  transform: rotate(90deg);
}
.pagination__link--next {
  transform: rotate(-90deg);
}

.partner-logos {
  background: var(--c-bg-dark);
  color: #FFF;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 768px) {
  .partner-logos {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media (min-width: 992px) {
  .partner-logos {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
}
.partner-logos__intro * {
  color: #FFF;
  margin: 0;
}
.partner-logos__intro * + * {
  margin-top: 0.5rem;
}
* + .partner-logos__image {
  margin-top: 2rem;
}
@media (min-width: 768px) {
  * + .partner-logos__image {
    margin-top: 2.5rem;
  }
}
@media (min-width: 992px) {
  * + .partner-logos__image {
    margin-top: 3rem;
  }
}
.partner-logos__divider {
  margin: 2rem 0;
  height: 1px;
  background: var(--c-divider-light);
}
@media (min-width: 768px) {
  .partner-logos__divider {
    margin: 2.5rem 0;
  }
}
@media (min-width: 992px) {
  .partner-logos__divider {
    margin: 3rem 0;
  }
}
.partner-logos__logos {
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem 2.5rem;
}
@media (min-width: 768px) {
  .partner-logos__logos {
    gap: 2.5rem 4rem;
  }
}
@media (min-width: 992px) {
  .partner-logos__logos {
    gap: 3rem 5rem;
  }
}
html.has-hover .partner-logos__logo {
  opacity: 0.7;
}
html.has-hover .partner-logos__logo:hover {
  opacity: 1;
}
.partner-logos__logo .image .image__wrapper {
  padding-top: 0 !important;
  height: auto;
}
.partner-logos__logo .image img {
  height: 2rem;
  max-width: none;
  width: auto;
  position: static;
}
@media (min-width: 768px) {
  .partner-logos__logo .image img {
    height: 2.5rem;
  }
}

.product-detail {
  padding-top: 3rem;
}
@media (min-width: 768px) {
  .product-detail {
    padding-top: 5rem;
  }
}
@media (min-width: 992px) {
  .product-detail {
    padding-top: 6.5rem;
  }
}
.product-detail__intro .richtext {
  max-width: 47.5rem;
  margin-bottom: 1.5rem;
}
.product-detail__intro .image {
  text-align: left;
}
.product-detail__intro hr {
  display: none;
  height: 1px;
  background: var(--c-divider-dark);
  border: none;
}
@media (min-width: 768px) {
  .product-detail__intro .richtext {
    margin-bottom: 0;
  }
  .product-detail__intro .image {
    text-align: right;
  }
  .product-detail__intro hr {
    display: block;
    margin-top: 3rem;
  }
}
@media (min-width: 992px) {
  .product-detail__intro hr {
    margin-top: 4rem;
  }
}
.product-detail__details {
  margin-top: 3rem;
}
.product-detail__details > h2 {
  margin-bottom: 1rem;
}
.product-detail__details > div:not(:last-child) {
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .product-detail__details {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
    margin-top: 5rem;
  }
  .product-detail__details > h2 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .product-detail__details > div {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .product-detail__details > div:not(:last-child) {
    margin-bottom: 3rem;
  }
}
@media (min-width: 992px) {
  .product-detail__details {
    margin-top: 6.5rem;
  }
  .product-detail__details > h2 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .product-detail__details > div {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .product-detail__details > div:not(:last-child) {
    margin-bottom: 4rem;
  }
}

.quotes__slide {
  margin: 0 auto;
  padding: 0 0 3.5rem;
  max-width: 47.5rem;
}
.quotes__slide .quotes__content {
  background: url(../images/quotationmark.svg) no-repeat center top;
  background-size: 4rem 4rem;
  padding-top: 5rem;
  text-align: center;
}
.quotes__slide .quotes__quote:before {
  content: "„";
}
.quotes__slide .quotes__quote:after {
  content: "“";
}
.quotes__slide .quotes__author {
  margin-top: 1rem;
}
.quotes__slide--with-image .quotes__image {
  position: relative;
}
.quotes__slide--with-image .quotes__image .image {
  width: 10.125rem;
}
.quotes__slide--with-image .quotes__eppm-line {
  display: none;
}
.theme-eppm .quotes__slide--with-image .quotes__eppm-line {
  display: block;
  position: absolute;
  left: -2.25rem;
  right: -2.25rem;
  bottom: -1.6875rem;
}
.theme-eppm .quotes__slide--with-image .quotes__eppm-line svg {
  width: 100%;
  height: auto;
}
.theme-eppm .quotes__slide--with-image .quotes__eppm-line svg path {
  transition: stroke-dashoffset 1800ms cubic-bezier(0.215, 0.61, 0.355, 1);
  stroke-dashoffset: 736px;
}
@media (prefers-reduced-motion: reduce) {
  .theme-eppm .quotes__slide--with-image .quotes__eppm-line svg path {
    stroke-dashoffset: 0;
  }
}
.theme-eppm .quotes__slide--with-image .quotes__eppm-line.js-visible svg path, .no-js .theme-eppm .quotes__slide--with-image .quotes__eppm-line svg path {
  stroke-dashoffset: 0;
}

.quotes__slide--with-image .quotes__eppg-lines {
  display: none;
}
.theme-eppg .quotes__slide--with-image .quotes__eppg-lines {
  display: block;
  position: absolute;
  left: -2.125rem;
  top: -1rem;
  width: calc(100% + 4.25rem);
  height: calc(100% + 2rem);
}
.theme-eppg .quotes__slide--with-image .quotes__eppg-lines svg {
  color: var(--c-accent);
  max-width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .theme-eppg .quotes__slide--with-image .quotes__eppg-lines {
    left: -2.3125rem;
    top: 2.0625rem;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 992px) {
  .theme-eppg .quotes__slide--with-image .quotes__eppg-lines {
    top: 3.1875rem;
    left: -3.8125rem;
  }
}

@media (max-width: 767.98px) {
  .quotes__slide--with-image .quotes__image {
    margin: 0 auto;
    margin-top: 1.5rem;
    width: 10.125rem;
  }
}
@media (min-width: 768px) {
  .quotes__slide--with-image {
    display: flex;
    align-items: center;
    max-width: 71rem;
  }
  .quotes__slide--with-image .quotes__content {
    flex: 1 1 auto;
    background-position-x: left;
    text-align: left;
  }
  .quotes__slide--with-image .quotes__image {
    flex: 0 0 auto;
    margin-left: 5.25rem;
  }
  .quotes__slide--with-image .quotes__image .image {
    width: 13.5rem;
  }
  .theme-eppm .quotes__slide--with-image .quotes__eppm-line {
    left: -3rem;
    right: -3rem;
    bottom: -2.25rem;
  }
}
@media (min-width: 992px) {
  .quotes__slide--with-image .quotes__image {
    margin-left: 8.75rem;
  }
  .quotes__slide--with-image .quotes__image .image {
    width: 20.25rem;
  }
  .theme-eppm .quotes__slide--with-image .quotes__eppm-line {
    left: -4.5rem;
    right: -4.5rem;
    bottom: -3.375rem;
  }
}
.quotes--single .quotes__slide {
  padding-bottom: 0;
}
.quotes .tns-nav {
  bottom: 0;
}
.quotes .tns-nav button {
  border: 0.0625rem solid var(--c-divider-dark);
  border-radius: 50%;
}
.quotes .tns-nav button.tns-nav-active:after {
  border-width: 0.4375rem;
}
.quotes .tns-nav button:not(.tns-nav-active):after {
  border-width: 0;
}
.quotes .tns-nav button:not(.tns-nav-active):hover, .quotes .tns-nav button:not(.tns-nav-active):focus {
  background: var(--c-bg-light);
}
@media (min-width: 992px) {
  .quotes__slide {
    padding-bottom: 4.5rem;
  }
  .quotes__author {
    margin-top: 1.5rem;
  }
}

.section.bg-grey {
  background: var(--c-bg-light);
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 768px) {
  .section.bg-grey {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media (min-width: 992px) {
  .section.bg-grey {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
}
.section.bg-grey + .section.bg-grey {
  padding-top: 0;
  margin-top: 0;
}
.section > .ep-module {
  margin-top: 0;
}
.section > .ep-module + .ep-module::before {
  content: "";
  display: block;
  width: calc(100% - 40px);
  max-width: 1376px;
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  background: var(--c-divider-dark);
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .section > .ep-module + .ep-module::before {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    width: calc(100% - 64px);
  }
}
@media (min-width: 992px) {
  .section > .ep-module + .ep-module::before {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
.section--hide-divider > .ep-module + .ep-module::before {
  background: none;
  height: 2.5rem;
  margin-bottom: 0;
  margin-top: 0;
}
@media (min-width: 768px) {
  .section--hide-divider > .ep-module + .ep-module::before {
    height: 5rem;
  }
}
@media (min-width: 992px) {
  .section--hide-divider > .ep-module + .ep-module::before {
    height: 6.5rem;
  }
}

.sitemap {
  padding-top: 0;
  padding-bottom: 0;
  /*
  *   NoScript Alternative
  */
}
.sitemap__intro {
  padding-top: 4rem;
  margin-bottom: 5rem;
}
@media (min-width: 768px) {
  .sitemap__intro {
    padding-top: 5rem;
  }
}
@media (min-width: 992px) {
  .sitemap__intro {
    padding-top: 6.5rem;
  }
}
.sitemap__intro .richtext {
  margin-top: 1.5rem;
}
.sitemap ul {
  margin: 0;
  padding: 0;
}
.sitemap button {
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
}
.sitemap a:hover {
  text-decoration: none;
}
.sitemap__nav ul {
  list-style: none;
}
.sitemap__menu-content {
  padding-bottom: 3rem;
}
@media (min-width: 768px) {
  .sitemap__menu-content {
    column-count: 2;
    padding-bottom: 4rem;
  }
}
@media (min-width: 992px) {
  .sitemap__menu-content {
    padding-bottom: 5rem;
  }
}
.sitemap__entry-lvl1 {
  break-inside: avoid;
  margin-bottom: 2.5rem;
}
.sitemap__entry-lvl1 a {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #3B8FD7;
}
.sitemap__entry-lvl1 a:hover {
  color: #3B8FD7;
}
.sitemap__entry-lvl1 > a {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.7777777778em;
  display: block;
}
.sitemap__entry-lvl2 a {
  font-size: 1.125rem;
  line-height: 1.3333333333em;
  display: block;
  color: #3B8FD7;
}
.sitemap__entry-lvl3 {
  padding-left: 1.5rem;
}

.slider {
  position: relative;
}
.slider__progress {
  background: var(--c-accent);
  height: 0.25rem;
  left: 0;
  position: absolute;
  right: 100%;
  top: 0;
  transform: translate3d(0, 0, 0);
  z-index: 1;
}
.slider__progress--running {
  right: 0;
  transition: all 8s linear;
}
.slider__slide {
  position: relative;
}
.slider__slide-content {
  background: rgba(0, 0, 0, 0.1);
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
}
.slider__slide-content .display-s,
.slider__slide-content .display-l {
  color: #FFF;
}
.slider__slide-content > *:not(:last-child) {
  margin-bottom: 1rem;
}
.slider__slide-content .btn {
  padding-bottom: 0.8125rem;
  padding-top: 0.9375rem;
}
@media (min-width: 576px) {
  .slider__slide-content {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}
@media (min-width: 768px) {
  .slider__slide-content {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
}
@media (min-width: 1200px) {
  .slider__slide-content {
    padding-left: 10.25rem;
    padding-right: 10.25rem;
  }
}

.sticky-call-to-action {
  display: flex;
  align-items: center;
  position: fixed;
  right: 1.875rem;
  bottom: 0;
  color: #FFF;
  background: var(--c-cta);
  box-shadow: 0 0.125rem 0.375rem 0 rgba(0, 0, 0, 0.15), 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem 0.75rem 0 0;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  z-index: 20;
}
.sticky-call-to-action .icon {
  width: 1.125rem;
  height: 1.125rem;
  fill: currentColor;
  margin-right: 0.5rem;
  transition: all 0.2s ease;
}
.sticky-call-to-action:hover, .sticky-call-to-action:focus {
  color: #FFF;
}
.sticky-call-to-action:hover .icon, .sticky-call-to-action:focus .icon {
  transform: scale(1.3333333333);
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.tag {
  align-items: center;
  border: 0.0625rem solid #999;
  border-radius: 0.125rem;
  color: #000;
  display: inline-flex;
  font-size: 1rem;
  line-height: 1.375em;
  min-height: 2.5rem;
  padding: 0.75rem;
}
.tag .icon {
  margin-right: 0.9375rem;
}

a.tag {
  cursor: pointer;
}
a.tag:hover, a.tag:focus {
  background: var(--c-accent-10);
  border-color: var(--c-accent);
  color: inherit;
}

.text {
  overflow: hidden;
}
.text .richtext:not(:first-child) {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .text .richtext:not(:first-child) {
    margin-top: 1.5rem;
  }
}
.text__entries {
  justify-content: center;
  margin-bottom: -1.5rem;
}
.text__entries--align-left {
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .text__entries {
    margin-bottom: -2.5rem;
  }
}
.text__entries--large-spacing {
  margin-bottom: -3rem;
}
@media (min-width: 768px) {
  .text__entries--large-spacing {
    margin-bottom: -5rem;
  }
}
.text__entry {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .text__entry {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 992px) {
  .text__entries--col-1 .text__entry {
    flex: 0 0 50%;
    max-width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
}
.text__entries--large-spacing .text__entry {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .text__entries--large-spacing .text__entry {
    margin-bottom: 5rem;
  }
}
@media (min-width: 992px) {
  .text__entries--align-left.text__entries--col-1 .text__entry {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .text__entries--col-2 .text__entry {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .text__entries--col-3 .text__entry {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (min-width: 992px) {
  .text__entries--col-4 .text__entry {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.text-box--bg-white .text-box__content {
  background: #FFF;
}
.text-box--bg-light .text-box__content {
  background: var(--c-bg-light);
}
.text-box--bg-dark .text-box__content {
  --c-headline: #FFF;
  --c-text: #FFF;
  background: var(--c-bg-dark);
  color: var(--c-text);
}
.text-box__copy {
  padding: 1.5rem 1rem;
}
@media (min-width: 768px) {
  .text-box__copy {
    padding: 2.5rem;
  }
}
.text-box__copy-big {
  padding: 1.5rem 1rem;
}
@media (min-width: 768px) {
  .text-box__copy-big {
    padding: 2.5rem;
  }
}
@media (min-width: 992px) {
  .text-box__copy-big {
    display: inline-flex;
    padding: 4rem;
    flex: 0 0 70%;
    width: 70%;
  }
}
.left .text-box__copy-big {
  flex: 0 0 70%;
  width: 70%;
}
.middle .text-box__copy-big {
  flex: 0 0 50%;
  width: 50%;
}
.right .text-box__copy-big {
  flex: 0 0 30%;
  width: 30%;
}
.text-box__copy-small {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .text-box__copy-small {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}
@media (min-width: 992px) {
  .text-box__copy-small {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
.text-box__copy-small-content {
  display: inline-flex;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}
@media (min-width: 768px) {
  .text-box__copy-small-content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@media (min-width: 992px) {
  .text-box__copy-small-content {
    padding-left: 1.5rem;
    padding-right: 3rem;
  }
}
.text-box__copy-small-content::before {
  content: "";
  position: absolute;
  top: -1.5rem;
  height: 0.0625rem;
  left: 1rem;
  right: 1rem;
  background: var(--c-divider-dark);
}
@media (min-width: 768px) {
  .text-box__copy-small-content::before {
    top: -2.5rem;
    left: 2.5rem;
    right: 2.5rem;
  }
}
@media (min-width: 992px) {
  .text-box__copy-small-content::before {
    right: auto;
    left: 0;
    top: 0;
    width: 0.0625rem;
    height: 100%;
  }
}
.text-box--bg-dark .text-box__copy-small-content::before {
  background: var(--c-divider-light);
}
.text-box__copy-small-image {
  width: 2.5rem;
  flex: 0 0 2.5rem;
  margin-right: 1rem;
}
.text-box__copy-small-text {
  flex: 1 1 auto;
}

.text-image__content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}
@media (min-width: 992px) {
  .text-image__content {
    align-items: flex-start;
  }
}
.text-image__image figure {
  margin: 0;
}
.text-image__text {
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .text-image__text {
    margin-top: 0;
  }
}
.text-image__eppg-lines {
  display: none;
}
@media (min-width: 768px) {
  .text-image--image-right .text-image__image {
    order: 1;
  }
  .text-image--image-right .text-image__text {
    order: -1;
  }
}
@media (min-width: 768px) {
  .text-image--image-centered .text-image__content {
    align-items: center;
  }
}
.text-image--line-pattern .image {
  margin-top: 1.8125rem;
  margin-bottom: 1.8125rem;
  position: relative;
}
@media (min-width: 768px) {
  .text-image--line-pattern .image {
    margin: 2.1875rem -2.1875rem 2.1875rem 2.1875rem;
  }
}
@media (min-width: 992px) {
  .text-image--line-pattern .image {
    margin: 3.25rem -3.25rem 3.25rem 3.25rem;
  }
}
.text-image--line-pattern .text-image__eppg-lines {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
}
.text-image--line-pattern .text-image__eppg-lines svg {
  color: var(--c-accent);
  max-width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .text-image--line-pattern .text-image__eppg-lines {
    left: 0.75rem;
    width: 80%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .text-image--line-pattern.text-image--image-right .image {
    margin-left: -2.1875rem;
    margin-right: 2.1875rem;
  }
}
@media (min-width: 992px) {
  .text-image--line-pattern.text-image--image-right .image {
    margin-left: -3.25rem;
    margin-right: 3.25rem;
  }
}
@media (min-width: 768px) {
  .text-image--line-pattern.text-image--image-right .text-image__eppg-lines {
    left: auto;
    right: 0.75rem;
  }
}

.eppg-stage {
  padding-top: 3rem;
}
.eppg-stage__text {
  max-width: 25rem;
  margin-bottom: 1.5rem;
}
.eppg-stage__text .display-xxl {
  margin-bottom: 0;
}
.eppg-stage__text .richtext,
.eppg-stage__text .txt-btn {
  margin-top: 1.5rem;
}
.eppg-stage__image {
  position: relative;
  text-align: right;
}
.eppg-stage__image .image {
  display: inline-block;
  width: 80%;
  margin-top: 4.375rem;
}
.eppg-stage__bg-lines {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  aspect-ratio: 688/456;
}
.eppg-stage__bg-lines svg {
  color: var(--c-accent);
  max-width: 100%;
  height: auto;
}
@media (min-width: 576px) {
  .eppg-stage__text {
    margin-bottom: 0;
  }
  .eppg-stage__image .image {
    margin-top: 3.125rem;
  }
}
@media (min-width: 768px) {
  .eppg-stage {
    padding-top: 5rem;
  }
}
@media (min-width: 992px) {
  .eppg-stage {
    padding-top: 6.5rem;
  }
  .eppg-stage__image .image {
    margin-top: 4.0625rem;
    width: 60%;
  }
  .eppg-stage__bg-lines {
    width: 72%;
  }
}

.ah-link {
  font-weight: 700;
  display: inline-block;
  color: var(--primary);
  transition: all 0.2s ease;
}
.ah-link svg {
  fill: var(--primary);
  position: relative;
  top: -0.05em;
  margin-left: 7px;
  transform: translateX(0px);
  transition: all 0.2s ease;
}
.ah-link:hover {
  text-decoration: none;
}
.ah-link:hover svg {
  transform: translateX(5px);
  fill: var(--c-link);
}

[data-href] {
  cursor: pointer;
}

.btn {
  font-weight: 700;
  display: inline-block;
  text-align: left;
  border: none;
  border-radius: 2px;
  padding: 1.3125em 2em 1.1875em;
  transition: all 0.2s ease;
  text-decoration: none;
  cursor: pointer;
  background-color: var(--c-link);
  color: #FFF;
  color: #FFF;
  background: var(--c-link);
}
.btn .icon {
  width: 1.5em;
  height: 1.5em;
  margin-top: -0.75em;
  margin-bottom: -0.75em;
  fill: currentColor;
}
.btn .icon:first-child {
  margin-right: 0.5rem;
}
.btn .icon:last-child {
  margin-left: 0.5rem;
}
.btn:hover, .btn.focus-visible {
  text-decoration: none;
  box-shadow: none;
  transform: translateY(-2px);
}
.btn.focus-visible {
  outline: 2px solid #000;
}
.btn--outline {
  color: var(--c-text);
  background: #FFF;
  border: 0.0625rem solid var(--c-divider-dark);
}

.txt-btn {
  font-weight: 700;
  display: inline-flex;
  padding: 0;
  color: var(--c-text);
  width: auto;
  transition: all 0.2s ease;
  text-decoration: none;
  text-align: left;
  border: 0;
  border-radius: 0;
  background: transparent;
}
.txt-btn:not(:last-child) {
  margin-right: 1rem;
}
.txt-btn > * {
  vertical-align: middle;
}
.txt-btn .icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5em;
  transition: all 0.2s ease;
  transform: scale(1);
  fill: currentColor;
  align-self: center;
  flex: 0 0 auto;
}
.txt-btn .icon:last-child {
  margin-left: 0.5em;
  margin-right: 0;
}
.txt-btn span {
  padding: 5px 0 3px;
  background-image: linear-gradient(to right, var(--c-link), var(--c-link));
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: 0 100%;
  transition: all 0.2s ease;
}
.txt-btn:hover, .txt-btn:focus {
  text-decoration: none;
  box-shadow: none;
  color: var(--c-text);
  transform: translateY(-2px);
}
.txt-btn:hover .icon, .txt-btn:focus .icon {
  transform: scale(1.3333333333);
}
.txt-btn:hover span, .txt-btn:focus span {
  background-size: 100% 3px;
}

.download-link {
  font-weight: 700;
  display: inline-flex;
  padding: 0;
  color: var(--c-text);
  width: auto;
  transition: all 0.2s ease;
  text-decoration: none;
  text-align: left;
  border: 0;
  border-radius: 0;
  background: transparent;
}
.download-link:not(:last-child) {
  margin-right: 1rem;
}
.download-link > * {
  vertical-align: middle;
}
.download-link .icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5em;
  transition: all 0.2s ease;
  transform: scale(1);
  fill: currentColor;
  align-self: center;
  flex: 0 0 auto;
}
.download-link .icon:last-child {
  margin-left: 0.5em;
  margin-right: 0;
}
.download-link span {
  padding: 5px 0 3px;
  background-image: linear-gradient(to right, var(--c-link), var(--c-link));
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: 0 100%;
  transition: all 0.2s ease;
}
.download-link:hover, .download-link:focus {
  text-decoration: none;
  box-shadow: none;
  color: var(--c-text);
  transform: translateY(-2px);
}
.download-link:hover .icon, .download-link:focus .icon {
  transform: scale(1.3333333333);
}
.download-link:hover span, .download-link:focus span {
  background-size: 100% 3px;
}

.external-link {
  font-weight: 700;
  display: inline-flex;
  padding: 0;
  color: var(--c-text);
  width: auto;
  transition: all 0.2s ease;
  text-decoration: none;
  text-align: left;
  border: 0;
  border-radius: 0;
  background: transparent;
}
.external-link:not(:last-child) {
  margin-right: 1rem;
}
.external-link > * {
  vertical-align: middle;
}
.external-link .icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5em;
  transition: all 0.2s ease;
  transform: scale(1);
  fill: currentColor;
  align-self: center;
  flex: 0 0 auto;
}
.external-link .icon:last-child {
  margin-left: 0.5em;
  margin-right: 0;
}
.external-link span {
  padding: 5px 0 3px;
  background-image: linear-gradient(to right, var(--c-link), var(--c-link));
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: 0 100%;
  transition: all 0.2s ease;
}
.external-link:hover, .external-link:focus {
  text-decoration: none;
  box-shadow: none;
  color: var(--c-text);
  transform: translateY(-2px);
}
.external-link:hover .icon, .external-link:focus .icon {
  transform: scale(1.3333333333);
}
.external-link:hover span, .external-link:focus span {
  background-size: 100% 3px;
}
.external-link .icon {
  order: 1;
  margin-left: 0.5em;
  margin-right: 0;
}

.header-404__headline {
  font-size: 6.25rem;
  line-height: 0.7em;
  margin: 0;
}
@media (min-width: 768px) {
  .header-404__headline {
    font-size: 12.5rem;
    line-height: 0.7em;
  }
}
.header-404__text {
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .header-404__text {
    margin-top: 2.5rem;
  }
}
@media (min-width: 992px) {
  .header-404__text {
    margin-top: 0;
  }
}
.header-404 form {
  margin: 0 -0.25rem -0.5rem;
}
.header-404 form > * {
  vertical-align: top;
  margin: 0 0.25rem 0.5rem;
}
.header-404 form:not(:first-child) {
  margin-top: 1.5rem;
}
.header-404 .js-autosuggest {
  display: inline-block;
  width: 30rem;
  max-width: 100%;
}
.header-404 .js-autosuggest > .form-group {
  width: 100%;
}
.header-404 .form-group {
  display: inline-block;
  margin: 0;
}
.header-404 .form-group .icon {
  width: 2rem;
  height: 2rem;
  right: 1rem;
}
.header-404 .form-group .form-control {
  height: 3.75rem;
  font-size: 1.25rem;
  line-height: 1.4em;
  padding-right: 3.5rem;
  padding-left: 1rem;
}
.header-404 .btn {
  height: 3.75rem;
  flex: 0 0 auto;
}

.page-intro {
  position: relative;
  overflow: hidden;
}
.page-intro:not(.page-intro--with-image.page-intro--image-as-bg) {
  padding-top: 3rem;
}
@media (min-width: 992px) {
  .page-intro:not(.page-intro--with-image.page-intro--image-as-bg) .row {
    flex-wrap: nowrap;
  }
}
@media (min-width: 768px) {
  .page-intro:not(.page-intro--with-image.page-intro--image-as-bg) {
    padding-top: 5rem;
  }
}
@media (min-width: 992px) {
  .page-intro:not(.page-intro--with-image.page-intro--image-as-bg) {
    padding-top: 6.5rem;
  }
}
.page-intro__text {
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
}
.page-intro__text .text-s {
  margin-bottom: 0.5rem;
}
.page-intro__text h1 {
  margin: 0;
}
.page-intro__text .richtext {
  margin-top: 0.5rem;
  max-width: 47.5rem;
}
.page-intro__button {
  position: relative;
  z-index: 1;
  align-self: flex-end;
  margin-top: 1.5rem;
}
@media (min-width: 992px) {
  .page-intro__button {
    text-align: right;
  }
}
.page-intro__image {
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .page-intro__image {
    margin-top: 3rem;
  }
}
@media (min-width: 992px) {
  .page-intro__image {
    margin-top: 4rem;
  }
}
.page-intro:not(.page-intro--with-image):not(.page-intro--image-as-bg) .page-intro__image {
  height: 1px;
  background: #C8C8C8;
}
.page-intro--with-image.page-intro--image-as-bg .page-intro__image .image {
  margin: 0;
}
.page-intro--with-image.page-intro--image-as-bg .page-intro__image .image * {
  padding-top: 0 !important;
}
.page-intro--with-image.page-intro--image-as-bg .page-intro__image .image .image__wrapper {
  position: static;
}
.page-intro--with-image.page-intro--image-as-bg .page-intro__image .image img {
  position: absolute;
}
.page-intro--with-image.page-intro--image-as-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--c-overlay);
}
.page-intro--with-image.page-intro--image-as-bg .container-fluid, .page-intro--with-image.page-intro--image-as-bg .container-sm, .page-intro--with-image.page-intro--image-as-bg .container-md, .page-intro--with-image.page-intro--image-as-bg .container-lg, .page-intro--with-image.page-intro--image-as-bg .container-xl {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.page-intro--with-image.page-intro--image-as-bg .container-fluid > .row, .page-intro--with-image.page-intro--image-as-bg .container-sm > .row, .page-intro--with-image.page-intro--image-as-bg .container-md > .row, .page-intro--with-image.page-intro--image-as-bg .container-lg > .row, .page-intro--with-image.page-intro--image-as-bg .container-xl > .row {
  align-content: center;
  text-align: center;
  min-height: 12.75rem;
}
@media (min-width: 768px) {
  .page-intro--with-image.page-intro--image-as-bg .container-fluid > .row, .page-intro--with-image.page-intro--image-as-bg .container-sm > .row, .page-intro--with-image.page-intro--image-as-bg .container-md > .row, .page-intro--with-image.page-intro--image-as-bg .container-lg > .row, .page-intro--with-image.page-intro--image-as-bg .container-xl > .row {
    min-height: 19rem;
  }
}
@media (min-width: 992px) {
  .page-intro--with-image.page-intro--image-as-bg .container-fluid > .row, .page-intro--with-image.page-intro--image-as-bg .container-sm > .row, .page-intro--with-image.page-intro--image-as-bg .container-md > .row, .page-intro--with-image.page-intro--image-as-bg .container-lg > .row, .page-intro--with-image.page-intro--image-as-bg .container-xl > .row {
    min-height: 25.25rem;
  }
}
.page-intro--with-image.page-intro--image-as-bg .page-intro__button {
  text-align: center;
}
.page-intro--with-image.page-intro--image-as-bg,
.page-intro--with-image.page-intro--image-as-bg h1,
.page-intro--with-image.page-intro--image-as-bg button,
.page-intro--with-image.page-intro--image-as-bg a {
  color: #FFF;
}
.page-intro--with-image.page-intro--image-as-bg .richtext {
  margin-left: auto;
  margin-right: auto;
}
.page-intro--with-image.page-intro--image-as-bg .page-intro__image {
  margin: 0;
}

.video-stage {
  position: relative;
}
.video-stage > button,
.video-stage .container-fluid > button,
.video-stage .container-sm > button,
.video-stage .container-md > button,
.video-stage .container-lg > button,
.video-stage .container-xl > button {
  display: block;
  appearance: none;
  width: 100%;
  padding: 0;
  border: none;
  margin: 0;
  background: transparent;
}
.video-stage__play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.35);
}
.video-stage__play-button .icon {
  width: 2rem;
  height: 2rem;
  fill: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease;
}
html.has-hover .video-stage button:hover .video-stage__play-button, .video-stage button.focus-visible .video-stage__play-button {
  background: #fff;
  transform: translate(-50%, -50%) scale(1.15);
}
html.has-hover .video-stage button:hover .video-stage__play-button .icon, .video-stage button.focus-visible .video-stage__play-button .icon {
  fill: #000;
}