.js-autosuggest {
  position: relative;

  &__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    background: $c-white;
    z-index: 1;
    box-shadow: 0 12px 18px -9px rgba(0,20,63,0.15);
    overflow: hidden;
  }

  &__entry {
    padding: em(6, 20) space(H);
    cursor: pointer;

    &:hover,
    &.active {
      color: var(--c-accent);
    }

    &:first-child {
      margin-top: space(I);
    }

    &:last-child {
      margin-bottom: space(I);
    }
  }

  .text-m {
    display: inline-block;
  }

  &[data-make-icon-submit] {
    // if wrapped around form group with icon, make the icon clickable
    .form-group .icon {
      pointer-events: all;
      cursor: pointer;
    }
  }

  html.no-js &__suggestions {
    display: none;
  }
}
