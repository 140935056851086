@import "node_modules/klaro/src/scss/klaro";

.klaro {
  --dark2: #5c5c5c;
  --dark3: #e6e6e6;
  --white3: #e6e6e6;
  --green1: var(--c-accent);
  --green2: var(--c-accent);
  --border-radius: 0;

  .context-notice {
    background: #d9d9d9;
    border: none;
  }

  .cookie-modal a,
  .context-notice a,
  .cookie-notice a {
    color: var(--white3);
  }

  .cookie-modal .cm-list-label .slider,
  .context-notice .cm-list-label .slider,
  .cookie-notice .cm-list-label .slider {
    &:before {
      background-color: var(--dark2);
    }
  }

  .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
  .context-notice .cm-list-input:checked + .cm-list-label .slider,
  .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
    &:before {
      background-color: var(--white3);
    }
  }

  &.cm-as-context-notice {
    padding: 0;
  }
}

[data-type="placeholder"] {
  @extend .embed-responsive-item;
  max-width: 100% !important;
  height: 100% !important;
}
