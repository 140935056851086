.sitemap {
  padding-top: 0;
  padding-bottom: 0;

  $root: &;

  &__intro {
    padding-top: space(C);
    margin-bottom: space(B);

    @include media-breakpoint-up(md) {
      padding-top: space(B);
    }

    @include media-breakpoint-up(lg) {
      padding-top: space(A);
    }

    .richtext {
      margin-top: space(G);
    }
  }

  $root: &;

  ul {
    margin: 0;
    padding: 0;
  }

  button {
    padding: 0;
    background: transparent;
    border: 0;
    border-radius: 0;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  /*
  *   NoScript Alternative
  */
  &__nav {
    ul {
      list-style: none;
    }
  }

  &__menu-content {
    padding-bottom: space(D);

    @include media-breakpoint-up(md) {
      column-count: 2;

      padding-bottom: space(C);
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: space(B);
    }

  }

  &__entry-lvl1 {

    break-inside: avoid;

    margin-bottom: space(E);

    a {
      padding-top: space(I);
      padding-bottom: space(I);
      color: $c-ep-blue;

      &:hover {

        color: $c-ep-blue;
      }
    }

    // title styles
    > a {
      @include f-helvetica-bold;
      font-size: rem(18);
      line-height: em(32, 18);
      display: block;

    }
  }

  &__entry-lvl2 a {
    font-size: rem(18);
    line-height: em(24, 18);
    display: block;

    color: $c-ep-blue;
  }

  &__entry-lvl3 {
    padding-left: space(G);
  }
}
