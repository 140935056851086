.slider {
  position: relative;

  &__progress {
    background: var(--c-accent);
    height: rem(4);
    left: 0;
    position: absolute;
    right: 100%;
    top: 0;
    transform: translate3d(0, 0, 0);
    z-index: 1;

    &--running {
      right: 0;
      transition: all 8s linear;
    }
  }

  &__slide {
    position: relative;

    &-content {
      background: transparentize($c-black, .9);
      align-items: center;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
      padding-left: rem(20);
      padding-right: rem(20);
      position: absolute;
      right: 0;
      top: 0;

      .display-s,
      .display-l {
        color: $c-white;
      }

      > *:not(:last-child) {
        margin-bottom: space(H);
      }

      .btn {
        padding-bottom: rem(13);
        padding-top: rem(15);
      }

      @include media-breakpoint-up(sm) {
        padding-left: rem(56);
        padding-right: rem(56);
      }

      @include media-breakpoint-up(md) {
        padding-left: rem(100);
        padding-right: rem(100);
      }

      @include media-breakpoint-up(xl) {
        padding-left: rem(164);
        padding-right: rem(164);
      }
    }
  }
}
