:root {
  --color-alpha: 1;
}

// set default color for button
button {
  color: var(--c-link);
}

body,
.theme-eppm {
  // default values EPPM
  --c-text: #{$c-black};
  --c-headline: #{$c-black};
  --c-link: #{$c-ep-blue};
  --c-accent: #{$c-ep-red};
  --c-accent-10: #{transparentize($c-ep-blue, .9)};
  --c-cta: linear-gradient(170deg, #800e06 0%, #a22c57 70%);
  --c-bg-light: #{$c-grey-15};
  --c-bg-dark: #{$c-black};
  --c-divider-light: #{$c-grey-40};
  --c-divider-dark: #{$c-grey};
  --c-footer: #{$c-black};
  --c-overlay: rgba(0,0,0,.15);
}

.theme-epem {
  --c-text: #{$c-black};
  --c-headline: #{$c-black};
  --c-link: #{$c-ep-blue};
  --c-accent: #{$c-ep-blue};
  --c-accent-10: #{transparentize($c-ep-blue, .9)};
  --c-cta: #{$c-ep-red};
  --c-bg-light: #{$c-grey-15};
  --c-bg-dark: #{$c-black};
  --c-divider-light: #{$c-grey-40};
  --c-divider-dark: #{$c-grey};
  --c-footer: #{$c-black};
  --c-overlay: rgba(0,0,0,.15);
}

.theme-eppg {
  --c-text: #{$c-black};
  --c-headline: #{$c-black};
  --c-link: #{$c-eppg-blue};
  --c-accent: #{$c-ep-red};
  --c-accent-10: #{transparentize($c-ep-red, .9)};
  --c-cta: linear-gradient(60deg, #002A45 0%, #024C74 100%);
  --c-bg-light: #{$c-grey-15};
  --c-bg-dark: #{$c-ep-red};
  --c-divider-light: #{$c-grey-40};
  --c-divider-dark: #{$c-grey};
  --c-footer: #{$c-ep-red};
  --c-overlay: rgba(0,0,0,.5);
}

.theme-euroment {
  --c-text: #{$c-black};
  --c-headline: #{$c-euroment-violet};
  --c-link: #{$c-euroment-violet};
  --c-accent: #{$c-euroment-violet};
  --c-accent-10: #{transparentize($c-euroment-violet, .9)};
  --c-cta: #{$c-euroment-violet};
  --c-bg-light: #{$c-grey-15};
  --c-bg-dark: #{$c-black};
  --c-divider-light: #{$c-grey-40};
  --c-divider-dark: #{$c-grey};
  --c-footer: #{$c-euroment-violet};
  --c-overlay: rgba(8,20,45,.45);
}

.theme-hoeltken {
  --c-text: #{$c-black};
  --c-headline: #{$c-hoeltken-blue};
  --c-link: #{$c-hoeltken-blue};
  --c-accent: #{$c-hoeltken-blue};
  --c-accent-10: #{transparentize($c-hoeltken-blue, .9)};
  --c-cta: #{$c-hoeltken-lime};
  --c-bg-light: #{$c-grey-15};
  --c-bg-dark: #{$c-hoeltken-blue};
  --c-divider-light: #{$c-grey-40};
  --c-divider-dark: #{$c-grey};
  --c-footer: #{$c-hoeltken-blue};
  --c-overlay: rgba(8,20,45,.45);
}

.theme-mp {
  --c-text: #{$c-black};
  --c-headline: #{$c-mp-blue};
  --c-link: #{$c-mp-blue};
  --c-accent: #{$c-mp-blue};
  --c-accent-10: #{transparentize($c-mp-blue, .9)};
  --c-cta: #{$c-mp-blue};
  --c-bg-light: #{$c-grey-15};
  --c-bg-dark: #{$c-mp-blue};
  --c-divider-light: #{$c-grey-40};
  --c-divider-dark: #{$c-grey};
  --c-footer: #{$c-mp-blue};
  --c-overlay: rgba(0,0,0,.15);
}

.theme-mps {
  --c-text: #{$c-black};
  --c-headline: #{$c-mps-blue};
  --c-link: #{$c-mps-blue};
  --c-accent: #{$c-mps-blue};
  --c-accent-10: #{transparentize($c-mps-blue, .9)};
  --c-cta: #{$c-mps-blue};
  --c-bg-light: #{$c-grey-15};
  --c-bg-dark: #{$c-mps-blue};
  --c-divider-light: #{$c-grey-40};
  --c-divider-dark: #{$c-grey};
  --c-footer: #{$c-mps-blue};
  --c-overlay: rgba(8,20,45,.45);
}
