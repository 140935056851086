.claim {
  padding-bottom: space(D);

  &:first-child {
    padding-top: space(D);
  }

  .container-fluid {
    position: relative;
  }

  &__line {
    display: none;

    @at-root .theme-eppm & {
      display: block;
      position: absolute;
      left: rem(-50);
      right: rem(-50);
      bottom: rem(-24);
      z-index: 1;

      svg {
        width: 100%;
        height: auto;

        path {
          transition: stroke-dashoffset 1800ms cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-delay: 1000ms;
          stroke-dashoffset: 1545px;

          @media (prefers-reduced-motion: reduce) {
            stroke-dashoffset: 0;
          }
        }
      }

      &.js-visible,
      .no-js & {
        svg path {
          stroke-dashoffset: 0;
        }
      }
    }
  }

  &__claim {
    font-weight: 300;
    font-size: rem(48);
    line-height: 1.1;
  }

  &__keyword {
    font-weight: 700;
    transform-origin: center left;

    &--fade-in {
      animation: 2s claim-keyword-in ease-out;
    }

    &--fade-out {
      animation: 2s claim-keyword-out ease-in;
    }
  }

  &__subline {
    margin-top: rem(24);
  }

  &__text,
  &__image {
    z-index: 1;
  }

  &__image {
    .image {
      transition: 1200ms transform ease-out, 1200ms opacity ease-out;
      opacity: 0;
      transform: translateY(rem(40));

      @media (prefers-reduced-motion: reduce) {
        opacity: 1;
        transform: translateY(0);
      }

      &.js-visible,
      .no-js & {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding-bottom: space(B);

    &:first-child {
      padding-top: space(B);
    }

    @at-root .theme-eppm &__line {
      bottom: rem(-104);
    }

    &__claim {
      font-size: rem(60);
    }
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: space(A);

    &:first-child {
      padding-top: space(A);
    }

    @at-root .theme-eppm &__line {
      bottom: rem(-128);
    }

    &__claim {
      font-size: rem(80);
    }
  }
}

@keyframes claim-keyword-in {
  0% {
    transform: scale(0.2);
    opacity: 0;
    letter-spacing: rem(4);
    filter: blur(rem(16));
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
    filter: blur(rem(4));
  }
  100% {
    transform: scale(1);
    letter-spacing: 0;
    filter: blur(0);
  }
}

@keyframes claim-keyword-out {
  50% {
    opacity: 1;
    transform: scale(1.1);
    filter: blur(4px);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
    letter-spacing: rem(4);
    filter: blur(rem(16));
  }
}
