$enable-rounded: true;
$font-family-base: 'Helvetica', Arial, sans-serif;
$line-height-base: 1.4;
$grid-gutter-width: 24px;

$body-color: $c-black;

$link-color: var(--c-link);
$link-hover-color: var(--c-link);

$border-radius: 2px;

// form variables
$input-color: $c-black;
$input-bg: #F8F8F8;
$input-border-color: #999;
$input-focus-border-color: var(--c-accent);
$input-focus-box-shadow: none;
$input-placeholder-color: rgba($c-black, 0.6);
$enable-shadows: false;
$input-border-width: 1px;

$form-feedback-invalid-color: $c-error;

$transition-base: all .2s ease;
