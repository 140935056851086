.header-404 {

  &__headline {
    font-size: rem(100);
    line-height: em(70, 100);
    margin: 0;

    @include media-breakpoint-up(md) {
      font-size: rem(200);
      line-height: em(140, 200);
    }
  }

  &__text {
    margin-top: space(G);

    @include media-breakpoint-up(md) {
      margin-top: space(E);
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  form {
    margin: 0 -.5 * space(I) -1 * space(I);

    > * {
      vertical-align: top;

      margin: 0 .5 * space(I) space(I);
    }

    &:not(:first-child) {
      margin-top: space(G);
    }
  }

  .js-autosuggest {
    display: inline-block;
    width: rem(480);
    max-width: 100%;

    > .form-group {
      width: 100%;
    }
  }

  .form-group {
    display: inline-block;
    margin: 0;

    .icon {
      width: rem(32);
      height: rem(32);

      right: rem(16);
    }

    .form-control {
      height: rem(60);
      font-size: rem(20);
      line-height: em(28, 20);

      padding-right: rem(16 + 32 + 8);
      padding-left: rem(16);
    }

  }

  .btn {
    height: rem(60);
    flex: 0 0 auto;
  }
}
