.bg-black {
  --c-text: #{$c-white};
  color: var(--c-text);
}

.bg-grey,
.bg-white,
.bg-default {
  color: var(--c-text);
}
