.form {

  $root: &;

  &__field-group {
    & + & {
      margin-top: space(E);

      @include media-breakpoint-up(lg) {
        margin-top: space(C);
      }
    }

    &-headline {
      margin-bottom: space(H);

      @include media-breakpoint-up(lg) {
        margin-bottom: space(G);
      }
    }
  }

  &__fields {
    margin-left: rem(-8);
    margin-right: rem(-8);
    > * {
      padding-left: rem(8);
      padding-right: rem(8);
    }

    @include media-breakpoint-up(lg) {
      margin-left: rem(-12);
      margin-right: rem(-12);
      > * {
        padding-left: rem(12);
        padding-right: rem(12);
      }
    }
  }

  &__field {
    margin-bottom: rem(16);
    @include media-breakpoint-up(lg) {
      margin-bottom: rem(24);
    }

    > .form-group {
      margin: 0;
    }

    &--headline {
      * + & {
        margin-top: rem(40 - 16);
        @include media-breakpoint-up(lg) {
          margin-top: rem(64 - 24);
        }
      }
    }

    &--headline-small {
      margin-top: rem(24 - 16);
      margin-bottom: rem(24);
      @include media-breakpoint-up(lg) {
        margin-top: rem(32 - 24);
        margin-bottom: rem(32);
      }
    }

    &--text-field {

      #{$root}__field--radio + & {
        margin-top: rem(8);
      }
    }

    &--subline {

      #{$root}__field--headline + & {
        margin-top: rem(-16);
      }
    }

    &--checkbox {
      * + & {
        margin-top: rem(40);
      }
    }

    &:first-child,
    &:first-child > :first-child {
      margin-top: 0;
    }

    &:last-child,
    &:last-child > :last-child {
      margin-bottom: 0 !important;
    }
  }

  &__aside {

    .richtext {
      overflow: initial;
    }

    * + & {
      margin-top: space(B);
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }
  }

  &__last-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: space(F);
    flex-wrap: wrap;

    p {
      width: 100%;
      flex: 0 0 100%;

      &:last-child {
        margin-top: space(H);
      }

      &:not(:last-child) {
        margin-bottom: space(H);
      }
    }

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;

      p {
        width: auto;
        flex: 1 1 auto;

        &:last-child {
          margin-top: 0;
        }

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }

      p:not(:last-child),
      .btn:not(:last-child) {
        margin-right: space(H);
      }
    }
  }

  &--compact {
    #{$root}__field {
        margin-bottom: space(H);
    }

    #{$root}__last-line {
      margin-top: space(H);
    }
  }
}
