.svg-ie-wrapper {
	position: relative;
	height: 0;

	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
