.pagination {
  margin-top: space(E);
  @include media-breakpoint-up(md) {
    margin-top: space(B);
  }
  @include media-breakpoint-up(lg) {
    margin-top: space(A);
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    gap: space(I);
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__link {
    a {
      @extend %text-m;
      @include transition($transition-base);
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(40);
      width: rem(40);
      color: var(--c-text);
      border: rem(1) solid var(--c-divider-dark);
      border-radius: 50%;

      &:hover,
      &:focus {
        color: $c-white;
        background: var(--c-link);
        border-color: var(--c-link);
        transform: scale(1.15);
      }
    }

    &--current a {
      color: $c-white;
      background: var(--c-link);
      border-color: var(--c-link);
    }

    &--prev,
    &--next {
      a {
        @include inline-svg-icon("chevron-down", $c-black, 'center', '24px 24px');

        &:hover,
        &:focus {
          @include inline-svg-icon("chevron-down", $c-white, 'center', '24px 24px');
        }
      }
    }

    &--prev {
      transform: rotate(90deg);
    }

    &--next {
      transform: rotate(-90deg);
    }
  }
}
