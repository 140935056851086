.image {

  $root: &;

  text-align: center;

  &--full-width {
    > * {
      max-width: none;
      padding: 0;

      .image > * {
        width: 100% !important;
      }
    }
  }
}
