.radio {

  $root: &;

  &.has-error {
    #{$root}__label {
      color: $c-error;
    }

    .invalid-feedback {
      display: block;
    }
  }

  &__label {
    @include f-helvetica-normal();
    font-size: rem(13);
    line-height: em(16, 13);
    margin-bottom: space(I);
    display: inline-block;
  }

  &__options-wrapper {
    margin-bottom: 1px;

    > * {
      float: left;
      margin-bottom: -1px !important;

      > div::before {
        border: 1px solid #999;
      }

      &.focus-within,
      html.has-hover &:hover {
        position: relative;
        z-index: 1;
        > div::before {
          border-color: var(--c-accent);
        }
      }
    }

    #{$root}--single-line & {
      > * {
        margin-right: -1px;

        &:first-child > div::before {
          border-radius: 2px 0 0 2px;
        }

        &:last-child > div::before {
          border-radius: 0 2px 2px 0;
        }
      }
    }

    #{$root}:not(#{$root}--single-line) & {
      > * {
        margin-right: -1px;
        width: 50%;

        > div::before {
          border-radius: 2px;
        }
      }
    }

    &::after {
      content: '';
      clear: left;
      display: table;
      width: 100%;
    }
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    min-height: rem(40);
    cursor: pointer;
    font-size: rem(16);
    line-height: em(22, 16);
    margin: 0;

    &:hover,
    &:focus,
    & {
      color: $c-black;
    }

    input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }

    > div {
      padding: em(20, 16) em(24, 16) em(20, 16) em(56, 16);
      font-size: rem(16);
      line-height: em(22, 16);

      &::before {
        content: '';
        position: absolute;
        top:0;left:0;bottom:0;right:0;
        pointer-events: none;
      }

      &::after {
        content: '';
        width: em(24, 16);
        height: em(24, 16);

        background: #F8F8F8;
        border: 1px solid rgba(0,0,0,.4);

        position: absolute;
        top: em(20, 16);
        left: em(16, 16);
        border-radius: 50%;
        z-index: 1;
      }

      div {
        position: relative;
        z-index: 1;
      }
    }

    input.focus-visible + div,
    html.has-hover &:hover > div {
      &::after {
        border-color: var(--c-accent);
        background-color: #FFF;
      }

      &::before {
        background: var(--c-accent-10);
      }
    }

    :checked + div,
    &.checked > div {
      &::after {
        border: em(8, 16) solid $c-black !important;
      }

      &::before {
        background: var(--c-accent-10);
        border-color: var(--c-accent);
      }
    }
  }

  a#{$root}__input-wrapper {
    &:focus {
      outline: none;
    }

    &.focus-visible div,
    &.focus-visible span {
      &::after {
        border: em(2, 16) solid $c-black;
      }
    }
  }


  &.has-error {
    label {
      color: var(--c-text);

      > div {
        &::before {
          border-color: $c-error;
        }
      }
    }
  }
}
