.navigation {
  background-color: $c-white;

  // &, * { outline: 1px solid red }

  $root: &;

  position: relative; // fallback IE11
  position: sticky;
  top: 0;
  z-index: $z-index-navigation;

  > * {
    z-index: 2;
    position: relative;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  button {
    padding: 0;
    background: transparent;
    border: 0;
    border-radius: 0;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    background: $c-white;

    height: rem($nav-height-mobile);
    @include media-breakpoint-up(lg) {
      height: rem($nav-height-desktop);
    }
  }

  &__logo {
    @extend %display-l;
    height: rem(40);
    line-height: rem(40);
    flex: 0 0 auto;
    @include media-breakpoint-up(lg) {
      height: rem(56);
      line-height: rem(56);
    }

    img {
      display: block;
      width: auto;
      height: 100%;
      max-width: none;
    }

    a {
      color: $c-black;
    }
  }

  &__desktop-nav {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      flex: 1 0 auto;
    }
  }

  &__main-nav,
  &__mobile-menu,
  &__mobile-language-selector {
    ul {
      list-style-type: none;
    }

    a {
      display: inline-block;
      font-size: rem(16);
      line-height: em(48, 16);
      min-width: 100%;

      color: $c-black;

      &.active {
        @include f-helvetica-bold;
      }

      .icon {
        width: em(16);
        height: em(16);
        position: relative;
        top: -.1em;
        @include media-breakpoint-up(lg) {
          width: em(20);
          height: em(20);
        }
      }
    }
  }

  &__main-nav {
    display: flex;
    justify-content: flex-end;
    font-size: 0;

    li > a {
      &.active,
      &.touch-click,
      &:hover,
      &:focus {
        position: relative;

        &::after {
          content: '';
          background: var(--c-accent);

          position: absolute;
          top: 0;
          width: calc(100% - #{space(F)});
          left: space(F) / 2;
          height: rem(2);
        }
      }
    }

    &__language-selector {
      margin-left: space(I);

      @include media-breakpoint-up(xl) {
        margin-left: space(G);
      }

      > a {
        @include f-helvetica-bold;

        .icon {
          width: rem(16);
          height: rem(16);
        }
      }
    }

    > ul > li:hover {
      > a {
        position: relative;

        &::after {
          content: '';
          background: var(--c-accent);

          position: absolute;
          top: 0;
          width: calc(100% - #{space(F)});
          left: space(F) / 2;
          height: rem(2);
        }
      }
    }

    > ul {
      list-style: none;

      > li {
        display: inline-block;

        > a {
          line-height: em(96, 16);
          padding-left: space(G) / 2;
          padding-right: space(G) / 2;

          @include media-breakpoint-up(xl) {
            padding-left: space(F) / 2;
            padding-right: space(F) / 2;
          }
        }
      }
    }

    > ul > li {
      height: rem(96);
      display: inline-flex;
      align-items: center;
    }

    > ul > li > ul,
    > ul > li > #{$root}__overlay {
      background: $c-white;
      position: absolute;
      top: 100%;

      li > a {
        padding: em(12, 16) space(G);
        line-height: inherit;

        &.active,
        &:hover,
        &:focus {
          &::after {
            display: block;
            top: 0;
            width: rem(2);
            left: 0;
            height: 100%;
          }
        }

        &:hover,
        &:focus {
          background: var(--c-bg-light);
          padding-left: space(F);
        }
      }
    }

    > ul > li > #{$root}__overlay {
      left: 0;
      width: 100%;
      display: flex;
      padding: space(I) space(I) space(F);

      > div {
        background: var(--c-bg-light);
        margin: 0 2.5%;
        padding: space(F);
        width: 35%;
        order: -1;

        @include media-breakpoint-up(xl) {
          padding: space(C);
          width: 45%;
        }

        .display-m:not(:last-child) {
          margin-bottom: space(G);
        }

        .richtext:not(:last-child) {
          margin-bottom: rem(21);
        }

        .txt-btn {
          line-height: 1.5;
          min-width: 0;
        }
      }

      > ul {
        @include make-row();
        display: flex !important;
        width: 60%;

        @include media-breakpoint-up(xl) {
          width: 50%;
        }

        > li {
          @include make-col-ready();
          @include make-col(1, 2);

          > a {
            font-weight: bold;

            &.active,
            &:hover,
            &:focus {
              background: inherit;
            }
          }

          > ul {
            margin-left: space(G);

            li > a {
              position: relative;

              &::after {
                content: '';
                background: var(--c-divider-dark);
                position: absolute;
                top: 0;
                width: rem(1);
                left: 0;
                height: 100%;
              }

              &.active,
              &:hover,
              &:focus {
                &:after {
                  background-color: var(--c-accent);
                }
              }
            }
          }
        }
      }
    }

    > ul > li > ul {
      width: 250px;
      padding-top: space(I);
      padding-bottom: space(I);

      > li > ul {
        margin-left: space(G);

        li > a {
          position: relative;

          &::after {
            content: '';
            background: var(--c-divider-dark);
            position: absolute;
            top: 0;
            width: rem(1);
            left: 0;
            height: 100%;
          }
        }
      }
    }

    > ul > li:nth-last-child(2),
    > ul > li:last-child {
      > ul {
        right: rem(32);
      }
    }

    ul &__language-selector ul {
      width: 100px !important;
    }

    //
    //  Hover Effect
    //
    > ul > li > ul,
    > ul > li > #{$root}__overlay {
      display: none;
    }

    > ul > li {
      > a, > button {
        &:hover,
        &:focus {
          & + ul {
            display: block;
          }

          & + #{$root}__overlay {
            display: flex;
          }
        }
      }
      &:hover,
      &:focus-within {
        ul {
          display: block;
        }

        #{$root}__overlay {
          display: flex;
        }
      }
    }


    [data-tablet-touch-visible] {
      display: none;
    }

    .js-touched + ul,
    .js-touched + ul [data-tablet-touch-visible] {
      display: block;
    }

    .js-touched + #{$root}__overlay {
      display: flex;
    }
  }

  &__language-selector {
    margin-left: space(D);
  }

  /*
  *   Mobile
  */
  &__mobile-nav {
    @include media-breakpoint-up(lg) {
      display: none;
    }

    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    button {
      margin: 0;
    }

    #{$root}__search {
      margin: 0;
    }
  }

  &__menu-btn {
    width: rem(48);
    height: rem(49);

    button {
      display: inline-block;
      position: relative;
      width: rem(48);
      height: rem(49);
      padding: 0;
      cursor: pointer;
      overflow: hidden;

      &.focus-visible {
        border: 2px solid $c-black;
      }

      > span:first-child {
        position: absolute;
        left: -9999px;
      }
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  //
  //  Triple Bar, ragged burger icon
  //
  &__menu-open-icon {
    display: inline-block;
    width: rem(48);
    height: rem(49);
    position: relative;
    transform-origin: 50% 50%;

    .bun-top,
    .patty-1,
    .patty-2,
    .bun-bottom {
      position: absolute;
      left: 0;
      top: 0;
      width: rem(24);
      height: rem(3);
      margin-left: 12px;
      background: #032A3F;
      transition: $transition-base;
      transform-origin: 50% 50%;
    }
    .bun-top {
      transform: translate(0px, 15px);
    }
    .bun-bottom {
      transform: translate(0px, 31px);
    }

    .patty-1,
    .patty-2 {
      transform: translate(0px, 23px);
    }

    #{$root}__menu-btn button.open & {
      .bun-top,
      .bun-bottom {
        transform: translate(0px, 23px);
        opacity: 0;
      }

      .patty-1 {
        transform: translate(0px, 23px) rotate(45deg);
      }
      .patty-2 {
        transform: translate(0px, 23px) rotate(-45deg);
      }
    }
  }

  &__mobile-menu {
    position: absolute;
    top: 100%;
    background: $c-white;

    height: calc(100vh - #{rem($nav-height-mobile)});
    height: calc(var(--app-height) - #{rem($nav-height-mobile)});
    @include media-breakpoint-up(md) {
      height: auto;
      max-height: calc(100vh - #{rem($nav-height-mobile)});
      max-height: calc(var(--app-height) - #{rem($nav-height-mobile)});
    }

    overflow: auto;
    transform: translateY(-100%);
    opacity: 0;
    transition: .2s transform ease-in-out, .3s opacity ease-in-out;
    z-index: 0;

    padding-top: space(I);
    padding-bottom: space(B);

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 100%;

      > * {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }

    #{$root}__mobile-main-nav {
      li {
        position: relative;

        li::after {
          content: '';
          background: $c-grey;
          display: block;

          position: absolute;
          top: 0;
          width: rem(1);
          left: 0;
          height: 100%;
        }
      }

      a {
        display: inline-block;
        position: relative;

        line-height: inherit;
        padding-left: space(G);
        padding-right: space(G);

        padding-top: em(12, 16);
        padding-bottom: em(12, 16);

        &.active,
        &:hover,
        &:focus {
          &::after {
            content: '';
            background: var(--c-accent);

            position: absolute;
            top: 0;
            width: rem(2);
            left: 0;
            height: 100%;
            z-index: 1;
          }
        }

        &:hover,
        &:focus {
          padding-left: space(F);
          background: var(--c-bg-light);

          &::after {
            display: block;
            top: 0;
            width: rem(2);
            left: 0;
            height: 100%;
          }
        }
      }

      ul {
        margin-left: space(G);

        a {
          &::before {
            content: '';
            background: $c-grey;

            position: absolute;
            top: 0;
            width: rem(1);
            left: 0;
            height: 100%;
          }
        }
      }
    }

    &.open {
      transform: translateY(0);
      opacity: 1;
    }

    @include media-breakpoint-up(md) {
      width: rem(320);
      max-width: 100vw;
      right: 0;
      padding-left: 0;
      padding-right: 0;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__mobile-language-selector {
    display: flex;

    $sel: &;
    &#{$sel} {
      margin-top: space(G);
    }

    a {
      width: rem(71);
      line-height: rem(48);
      text-align: center;

      &.active {
        @include f-helvetica-bold;
      }

      &:hover,
      &.focus-visible {
        background: var(--c-bg-light);
      }
    }
  }
}
