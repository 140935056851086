$container-max-width: 1440px;

.container-fluid {
  max-width: $container-max-width;
}

:root {
  --app-height: 100vh;
}

html {
  overflow: hidden auto;

  &.locked {
    overflow: hidden;
  }
}

body {
  > main,
  > .content-wrapper {
    html.text-size-large & {
      max-width: none;
    }
  }


  &.preview > .content-wrapper {
    background: transparent;
    min-height: 100vh;
  }
}
