$spacings: (
  'A': rem(104),
  'B': rem(80),
  'C': rem(64),
  'D': rem(48),
  'E': rem(40),
  'F': rem(32),
  'G': rem(24),
  'H': rem(16),
  'I': rem(8)
);
// call like margin-top: space(A)
@function space($space) {
  @return map-get($spacings, $space);
}

/*
*   Module Spacings
*/

.ep-module + .ep-module,
.footer {
  margin-top: space(D);

  @include media-breakpoint-up(md) {
    margin-top: space(B);
  }

  @include media-breakpoint-up(lg) {
    margin-top: space(A);
  }
}

// remove footer margin between bg module and footer
.ep-module--with-bg:last-child {
  margin-bottom: -1 * space(D);

  @include media-breakpoint-up(md) {
    margin-bottom: -1 * space(B);
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: -1 * space(A);
  }
}

// remove top margin when two bg modules are adjacent
.ep-module--with-bg + .ep-module--with-bg {
  margin-top: 0;
}

.preview main {
  padding-top: space(D);
  padding-bottom: space(D);

  @include media-breakpoint-up(md) {
    padding-top: space(B);
    padding-bottom: space(B);
  }

  @include media-breakpoint-up(lg) {
    padding-top: space(A);
    padding-bottom: space(A);
  }
}


/*
 *  Text Spacings (ordered smalles spacing first)
 */
.richtext {

  // standard paragraph
  .text-m,
  p,
  h1,
  h2,
  h3 {
    margin-top: space(H);
    margin-bottom: space(H);
  }

  .display-s {
    margin-top: space(H);
    margin-bottom: space(H);

    @include media-breakpoint-up(lg) {
      margin-top: space(G);
      margin-bottom: space(G);
    }
  }

  .display-xl + .display-s {
    margin-top: -1 * space(G);  // collapse bottom margin of display-xl
  }

  .display-l + .display-s,
  .display-m + .display-s {
    margin-top: -1 * space(H);  // collapse bottom margin of display-*
  }

  @include media-breakpoint-up(md) {
    .display-xl + .display-s {
      margin-top: -1 * space(G) + space(I);   // collapse and add space I
    }
  }

  @include media-breakpoint-up(lg) {
    .display-l + .display-s {
      margin-top: -1 * space(G) + space(I);   // collapse and add space I
    }
    .display-m + .display-s {
      margin-top: -1 * space(G);   // collapse and add space I
    }
  }

  .display-m {
    margin-top: space(F);
    margin-bottom: space(H);

    @include media-breakpoint-up(lg) {
      margin-top: space(E);
      margin-bottom: space(G);
    }
  }

  .display-l {
    margin-top: space(F);
    margin-bottom: space(H);

    @include media-breakpoint-up(lg) {
      margin-top: space(E);
      margin-bottom: space(G);
    }
  }

  .display-xl {
    margin-top: space(F);
    margin-bottom: space(G);

    @include media-breakpoint-up(md) {
      margin-top: space(E);
    }
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}
