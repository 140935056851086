.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: space(H);
}

.tag {
  align-items: center;
  border: rem(1) solid #999;
  border-radius: rem(2);
  color: $c-black;
  display: inline-flex;
  font-size: rem(16);
  line-height: em(22, 16);
  min-height: rem(40);
  padding: rem(12);

  .icon {
    margin-right: rem(15);
  }
}

a.tag {
  cursor: pointer;

  &:hover,
  &:focus {
    background: var(--c-accent-10);
    border-color: var(--c-accent);
    color: inherit;
  }
}
